import { ApiSuccessResponse } from "./api-http-requests";
import { Appointment } from "./appointment";
import { UserPublicDataFragment } from "./user";

export enum UserInviteAction {
    LINK_USERS = "link_users",
    JOIN_APPOINTMENT = "join_appointment"
}

export enum UserInviteActionErrorCode {
    INVITER_LINKED_USERS_LIMIT_EXCEEDED = "inviter_linked_users_limit_exceeded",
    ACCEPTOR_LINKED_USERS_LIMIT_EXCEEDED = "acceptor_linked_users_limit_exceeded",
    USERS_ALREADY_LINKED = "users_already_linked",
    INVALID_APPOINTMENT_STATE = "invalid_appointment_state",
    USER_ALREADY_PARTICIPATING = "user_already_participating",
    APPOINTMENT_PARTICIPANT_LIMIT_EXCEEDED = "appointment_participant_limit_exceeded"
}

type UserInviteActionLinkUsers = {
    action: UserInviteAction.LINK_USERS;
};
type UserInviteActionJoinAppointment = {
    action: UserInviteAction.JOIN_APPOINTMENT;
    appointment: Appointment;
};

export const UserInviteActionErrorCodeLabel = Object.freeze({
    [UserInviteActionErrorCode.INVITER_LINKED_USERS_LIMIT_EXCEEDED]:
        "Osoba, która wygenerowała zaproszenie ma już maksymalną ilość osób dodanych do powiązanych kont",
    [UserInviteActionErrorCode.ACCEPTOR_LINKED_USERS_LIMIT_EXCEEDED]:
        "Osoba, która akceptuje zaproszenie ma już maksymalną ilość osób dodanych do powiązanych kont",
    [UserInviteActionErrorCode.USERS_ALREADY_LINKED]: "Konta tych użytkowników są już powiązane",
    [UserInviteActionErrorCode.INVALID_APPOINTMENT_STATE]:
        "Spotkanie zostało zakończone lub anulowane",
    [UserInviteActionErrorCode.USER_ALREADY_PARTICIPATING]:
        "Przyjmujący zaproszenie jest już uczestnikiem tego spotkania",
    [UserInviteActionErrorCode.APPOINTMENT_PARTICIPANT_LIMIT_EXCEEDED]:
        "Spotkanie nie pozwala już na dołączenie kolejnego uczestnika"
});

export const UserInviteActionLabel = Object.freeze({
    [UserInviteAction.LINK_USERS]: "dołączenia do grona jego powiązanych kont",
    [UserInviteAction.JOIN_APPOINTMENT]: "dołączenia do następującej wizyty"
});

export const UserInviteAcceptResponseFailedActionLabel = Object.freeze({});

export type UserInviteActionEntry = UserInviteActionLinkUsers | UserInviteActionJoinAppointment;

export type UserInvite = {
    _id: string;
    access_token: string;
    created_by: UserPublicDataFragment;
    actions: UserInviteActionEntry[];
    c_date: number;
    m_date: number;
};

/*###################
### HTTP REQUESTS ###
###################*/
export type ApiUserInviteResponse = ApiSuccessResponse & {
    user_invite: UserInvite;
};

type ApiUserInviteAcceptResponseDataEntrySucceeded = {
    action: UserInviteAction;
    success: true;
};
type ApiUserInviteAcceptResponseDataEntryFailed = {
    action: UserInviteAction;
    success: false;
    error_code: string;
};
type ApiUserInviteAcceptResponseDataEntry =
    | ApiUserInviteAcceptResponseDataEntrySucceeded
    | ApiUserInviteAcceptResponseDataEntryFailed;

export type ApiUserInviteAcceptResponse = ApiSuccessResponse & {
    data: ApiUserInviteAcceptResponseDataEntry[];
};
