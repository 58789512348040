<template>
    <button
        class="risify-radio-element"
        :class="{
            ['risify-radio-element--' + element_size]: true,
            'risify-radio-element--checked': is_checked,
            'risify-radio-element--invalid': error,
            'risify-radio-element--disabled': disabled
        }"
        autocomplete="off"
        :disabled="!is_clickable"
        :tabindex="!is_clickable ? '-1' : '0'"
        @click.stop="toggleElement"
    >
        <div class="risify-radio-element__fill"></div>
    </button>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";

export type RisifyRadioElementValue<T> = T | undefined;
export type RisifyRadioElementProps<T> = {
    disabled?: boolean;
    readonly?: boolean;

    error?: boolean;

    trueValue: T;
    modelValue: RisifyRadioElementValue<T>;

    xSmall?: boolean;
    small?: boolean;
    large?: boolean;
    xLarge?: boolean;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifyRadioElementProps<T>>(), {
    disabled: false,
    readonly: false,
    error: false
});

const emit = defineEmits<{
    (e: "update:modelValue", v: RisifyRadioElementValue<T>): void;
    (e: "click", event: MouseEvent | PointerEvent): void;
}>();

/*###############
### VARIABLES ###
###############*/

/*##############
### COMPUTED ###
##############*/
const is_checked = computed(() => {
    return props.modelValue === props.trueValue;
});
const element_size = computed(() => {
    if (props.xSmall === true) {
        return "xsmall";
    }
    if (props.small === true) {
        return "small";
    }
    if (props.large === true) {
        return "large";
    }
    if (props.xLarge === true) {
        return "xlarge";
    }

    return "regular";
});
const is_clickable = computed(() => props.disabled !== true && props.readonly !== true);

/*#############
### METHODS ###
#############*/
function toggleElement(e: MouseEvent | PointerEvent) {
    if (props.disabled) return;
    emit("click", e);
    emit("update:modelValue", props.trueValue);
}

/*##############
### EXPPOSES ###
##############*/
defineExpose({
    toggleElement,
    is_checked
});
</script>
