<template>
    <div class="default-layout">
        <UiAlerts />
        <MobileMenu :visible="mobile_menu" />
        <AppointmentDetailsView />
        <div
            class="default-layout-content"
            ref="layout_content"
        >
            <slot />
        </div>

        <BottomNavigation v-if="router_ready && show_bottom_navigation" />

        <div
            :class="{
                'default-layout-nsp text-link-3': true,
                'default-layout-nsp--visible': show_network_status_prompt,
                'default-layout-nsp default-layout-nsp--online': generalStore.is_online,
                'default-layout-nsp default-layout-nsp--offline': !generalStore.is_online
            }"
        >
            {{ generalStore.is_online ? "Odzyskano połączenie" : "Brak połączenia" }}
        </div>
    </div>

    <Dialog
        v-model="show_offline_dialog"
        persistent
    >
        <DialogBaseHeader>Jesteś offline</DialogBaseHeader>
        <DialogBaseBody
            >Aplikacja nie ma&nbsp;połączenia z&nbsp;Internetem&nbsp;- dopóki połączenie
            nie&nbsp;zostanie nawiązane, nie ma&nbsp;możliwości przeprowadzenia żadnych operacji,
            a&nbsp;wyświetlane w&nbsp;aplikacji dane pochodzą z&nbsp;pamięci cache.</DialogBaseBody
        >
        <template #footer>
            <div class="default-layout-offline-dlg__footer">
                <DialogBaseFooter>
                    <RisifyButton
                        class="ml-auto"
                        color="yellow"
                        @click="show_offline_dialog = false"
                        >Zamknij</RisifyButton
                    >
                </DialogBaseFooter>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useGeneralStore } from "@/stores/general";
import { emitter } from "@/plugins/eventEmitter";
import AppointmentDetailsView from "@/partials/AppointmentDetailsView.vue";
import BottomNavigation from "@/components/navigation/BottomNavigation.vue";
import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import MobileMenu from "@/components/navigation/MobileMenu.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import UiAlerts from "@/partials/UiAlerts.vue";
import { EmitterDefaultLayoutScrollLayoutArgs } from "@/types/emitter";
import { useDefaultLayout } from "@/helpers/layout";
import { useWindowSize } from "@vueuse/core";

/*###########
### SETUP ###
###########*/
const generalStore = useGeneralStore();
const router = useRouter();
const { is_bottom_navigation_visible } = useDefaultLayout();
const { width: window_width } = useWindowSize();

/*###############
### VARIABLES ###
############## */
const layout_content = ref<HTMLElement | null>(null);

const mobile_menu = ref<boolean>(false);
const bottom_navigation_hidden_manually = ref(false);
const router_ready = ref(false);

/*###########################
### NETWORK STATUS PROMPT ###
########################## */
// const network_status_prompt_ref = ref<HTMLElement | null>(null);
const show_network_status_prompt = ref(false);

// const network_status_prompt_height = computed(() => {
//     if (network_status_prompt_ref.value) {
//         return network_status_prompt_ref.value.offsetHeight || 0;
//     }
// });

/*####################
### OFFLINE DIALOG ###
################### */
const show_offline_dialog = ref(false);

const showOfflineDialog = () => {
    show_offline_dialog.value = true;
};

/*##############
### COMPUTED ###
############# */
const show_bottom_navigation = computed(() => {
    return !bottom_navigation_hidden_manually.value && is_bottom_navigation_visible.value;
});

/*##############
### WATCHERS ### 
##############*/
watch(window_width, calcMainLayoutScrollbarWidth, {
    immediate: true
});

watch(
    () => generalStore.is_online,
    nv => {
        if (nv) {
            show_network_status_prompt.value = true;
            setTimeout(() => {
                show_network_status_prompt.value = false;
            }, 2500);
        } else {
            show_network_status_prompt.value = true;
        }
    }
);

/*#############
### METHODS ###
############ */
function handleLayoutScroll() {
    generalStore.setMainLayoutLastScrollPosition(layout_content.value?.scrollTop || 0);
    generalStore.setMainLayoutLastScrollHeight(layout_content.value?.scrollHeight || 0);
    generalStore.setMainLayoutLastOffsetHeight(layout_content.value?.offsetHeight || 0);
}

function scrollLayout(y: number, animate: boolean = false) {
    layout_content.value?.scrollTo({
        left: 0,
        top: y,
        behavior: animate ? "smooth" : "instant"
    });
}

function calcMainLayoutScrollbarWidth() {
    if (layout_content.value) {
        generalStore.setMainLayoutClientWidth(layout_content.value.clientWidth);
        generalStore.setMainLayoutScrollbarWidth(
            layout_content.value.offsetWidth - layout_content.value.clientWidth
        );
    } else {
        generalStore.setMainLayoutClientWidth(window_width.value);
    }
}

function hideBottomNavigation() {
    bottom_navigation_hidden_manually.value = true;
}
function showBottomNavigation() {
    bottom_navigation_hidden_manually.value = false;
}

function hideMobileMenu(): void {
    mobile_menu.value = false;
}

function showMobileMenu(): void {
    mobile_menu.value = true;
}

function onEmitterScrollLayout(args: EmitterDefaultLayoutScrollLayoutArgs) {
    scrollLayout(args.y, args.animate);
}

/*###########
### HOOKS ###
########## */
onMounted(() => {
    generalStore.setMainLayoutLastScrollPosition(0);

    emitter.on("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.on("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.on("DefaultLayout::showBottomNavigation", showBottomNavigation);
    emitter.on("DefaultLayout::hideMobileMenu", hideMobileMenu);
    emitter.on("DefaultLayout::showMobileMenu", showMobileMenu);
    emitter.on("DefaultLayout::showOfflineDialog", showOfflineDialog);

    layout_content.value?.addEventListener("scroll", handleLayoutScroll, {
        passive: true
    });

    calcMainLayoutScrollbarWidth();

    router.isReady().then(() => {
        router_ready.value = true;
    });
});

onUnmounted(() => {
    layout_content.value?.removeEventListener("scroll", handleLayoutScroll);
    emitter.off("DefaultLayout::scrollLayout", onEmitterScrollLayout);
    emitter.off("DefaultLayout::hideBottomNavigation", hideBottomNavigation);
    emitter.off("DefaultLayout::showBottomNavigation", showBottomNavigation);
    emitter.off("DefaultLayout::hideMobileMenu", hideMobileMenu);
    emitter.off("DefaultLayout::showMobileMenu", showMobileMenu);
    emitter.off("DefaultLayout::showOfflineDialog", showOfflineDialog);
});

defineExpose({
    scrollLayout,
    hideBottomNavigation,
    showBottomNavigation
});
</script>
