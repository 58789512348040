<template>
    <div
        class="sq-sc my-4 text-label-1"
        ref="question_title_ref"
    >
        {{ question.title }}<span class="sq-sc__asterisk">{{ is_required ? "*" : "" }}</span>
    </div>
    <div
        v-if="question.single_choice.layout === SurveyQuestionSingleChoiceLayout.LIST_1COL"
        class="sq-sc__layout sq-sc__layout--1col"
    >
        <RisifyRadio
            class-name="mb-2"
            v-for="choice in question.choices"
            :key="choice.id"
            :label="choice.label"
            v-model="selected_choice"
            @update:model-value="
                selected_choice = choice.id;
                validate();
            "
            :true-value="choice.id"
            outlined
            variant="tile"
        >
        </RisifyRadio>
    </div>
    <div
        v-else-if="question.single_choice.layout === SurveyQuestionSingleChoiceLayout.LIST_2COL"
        class="sq-sc__layout sq-sc__layout--2col"
    >
        <RisifyRadio
            v-for="choice in question.choices"
            :key="choice.id"
            :label="choice.label"
            v-model="selected_choice"
            @update:model-value="
                selected_choice = choice.id;
                validate();
            "
            :true-value="choice.id"
            outlined
            variant="tile"
        >
        </RisifyRadio>
    </div>
    <p class="sq-sc__errmsg">{{ question_error }}</p>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import RisifyRadio from "@/components/form-inputs/RisifyRadio.vue";
import {
    SurveyQuestionSingleChoice,
    SurveyQuestionValue,
    SurveyQuestionExpose,
    SurveyQuestionSingleChoiceLayout
} from "@/types/surveys";

const props = defineProps<{
    question: SurveyQuestionSingleChoice;
}>();

const selected_choice = ref<string>("");
const question_error = ref<string>("");
const is_required = computed(() => {
    return props.question.single_choice.required;
});

const question_title_ref = ref<HTMLElement>();

defineExpose<SurveyQuestionExpose>({
    getValue,
    validate,
    getAnchorElement: () => question_title_ref.value
});

function getValue(): SurveyQuestionValue | null {
    return selected_choice.value
        ? { question: props.question._id, values: [selected_choice.value] }
        : null;
}

function validate(): boolean {
    if (selected_choice.value.length < 1 && is_required.value) {
        question_error.value = "Odpowiedź jest wymagana";
        return false;
    } else {
        question_error.value = "";
    }
    return true;
}
</script>
