import { io } from "socket.io-client";
import { useAuthStore } from "../stores/auth";
import { useChatsStore } from "../stores/chats";
import { useGeneralStore } from "../stores/general";
import {
    ApiSocketGeneralResponse,
    IoAppointmentPayload,
    IoAppointmentRescheduleProposalPayload,
    IoChatMessagePayload,
    IoChatPayload,
    IoDeletedChatMessagePayload,
    IoEmit,
    IoEvent,
    IoPredefinedRooms
} from "../types/socket-io";
import {
    ChatMessageAuthorType,
    ChatMessageEventName,
    ChatMessageMessageType,
    NewChatMessageUIAlertProps,
    NotificationType
} from "@/types/chat-message";
import { shouldReportChatMessageToMessageLog } from "@/helpers/chats";
import { httpJsonRequest } from "./fetchApi";
import { appIsFocused } from "@/helpers/generics";
import { displayAlert } from "@/main";
import { NotificationClearMethods } from "notivue";
import { playNewNotificationSound } from "@/helpers/sounds";
import { useFetchedElementsStore } from "@/stores/fetched-elements";
import { ApiChatResponse, ChatThusappr, ChatType } from "@/types/chat";

let socket_logged_in: boolean = false;
let socket_login_tries: number = 0;
let socket_events_inited: boolean = false;
const new_chat_message_ui_notifications = new Map<string, NotificationClearMethods>();
const JOINED_ROOMS: Set<IoPredefinedRooms> = new Set();

export const IO = io(import.meta.env.VITE_API_BASE_URL, {
    transports: ["websocket"],
    autoConnect: false
});

export function ioConnect() {
    return new Promise<void>((resolve, reject) => {
        IO.io.connect(err => {
            if (err) {
                console.error(`[Socket.io] Could not connect to server:`);
                return reject(err);
            }
            IO.connect();
            console.log(`[Socket.io] Connected`);
            return resolve();
        });
    });
}
export function ioDisconnect() {
    IO.disconnect();
}

export function initIoEvents() {
    if (socket_events_inited) return;

    IO.on("disconnect", async reason => {
        console.log(`[Socket.io] Disconnected (${reason})`);
        socket_logged_in = false;
    });
    IO.io.on("reconnect", async () => {
        console.log(`[Socket.io] Reconnected`);
        await authorizeInSocketIo();

        const PRTJ = [...JOINED_ROOMS];
        JOINED_ROOMS.clear();
        for (let i = 0; i < PRTJ.length; i++) {
            await joinPredefinedRoom(PRTJ[i]);
        }
    });

    IO.on(IoEvent.ME_UPDATED, async () => {
        console.log("[Socket.io] Me.updated");
        const auth_store = useAuthStore();
        auth_store.fetchUserData().catch(err => console.error(err));
    });

    IO.on(IoEvent.CHAT_CREATED, async (body: IoChatPayload) => {
        // console.log("[Socket.io] Chat.created", body);
        const chats_store = useChatsStore();
        chats_store.insertChat(body.chat);
    });
    IO.on(IoEvent.CHAT_UPDATED, async (body: IoChatPayload) => {
        // console.log("[Socket.io] Chat.updated", body);
        const chats_store = useChatsStore();
        chats_store.updateChat(body.chat);
    });

    IO.on(IoEvent.CHAT_MESSAGE_CREATED, async (body: IoChatMessagePayload) => {
        // console.log("[Socket.io] ChatMessage.created", body);
        const auth_store = useAuthStore();

        // 1. Jeżeli wiadomość jest autorstwa użytkownika i nie jest zdarzeniem, to nie obsługujemy takiego zdarzenia
        if (
            auth_store.user &&
            body.chat_message.author_type === ChatMessageAuthorType.USER &&
            auth_store.user._id === body.chat_message.user._id &&
            body.chat_message.deleted === false &&
            body.chat_message.message_type !== ChatMessageMessageType.EVENT
        ) {
            return;
        }

        const general_store = useGeneralStore();
        // 2. Dodajemy wiadomość do Store
        const chats_store = useChatsStore();
        chats_store.insertChatMessage(body.chat_message);

        // 3. Oznaczamy wiadomość:
        let chat = chats_store.getChat(body.chat_message.chat);

        if (chat === undefined) {
            try {
                const res = await httpJsonRequest<ApiChatResponse<ChatThusappr>>(
                    `/chats/${body.chat_message.chat}`,
                    {},
                    { supress_errors: true }
                );
                chats_store.insertChat(res.chat);
                chat = chats_store.getChat(body.chat_message.chat);
            } catch (e) {
                console.log(e);
                return;
            }
        }

        if (chat !== undefined && auth_store.user) {
            // 3.1. Jako dostarczoną
            if (
                shouldReportChatMessageToMessageLog(
                    body.chat_message,
                    chat.ld_message,
                    auth_store.user._id
                ) === "report"
            ) {
                await httpJsonRequest(
                    `/chats/${chat._id}/message-log`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            chat_message: body.chat_message._id,
                            state: "delivered"
                        })
                    },
                    {
                        supress_errors: true
                    }
                );
            }

            // 3.2. Jako odczytaną jeżeli dany czat i przeglądarka są aktywne
            if (
                shouldReportChatMessageToMessageLog(
                    body.chat_message,
                    chat.lr_message,
                    auth_store.user._id
                ) === "report" &&
                chats_store.isChatActive(chat._id) &&
                (await appIsFocused())
            ) {
                await httpJsonRequest(
                    `/chats/${chat._id}/message-log`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            chat_message: body.chat_message._id,
                            state: "read"
                        })
                    },
                    {
                        supress_errors: true
                    }
                );
            }
        }
        // 4. Powiadomienia lokalne
        const MESSAGE_FROM_USER_DIFFERENT_THAN_LOGGED_IN =
            auth_store.user &&
            body.chat_message.author_type === ChatMessageAuthorType.USER &&
            auth_store.user._id !== body.chat_message.user._id;
        const NOT_EVENT_MESSAGE =
            body.chat_message.deleted === false &&
            body.chat_message.message_type !== ChatMessageMessageType.EVENT;
        const THUSAPPRCHAT_MESSAGE = chat && chat.type === ChatType.THUS_APPOINTMENT_PRODUCT;
        const ALLOWED_EVENT_MESSAGE =
            body.chat_message.deleted === false &&
            body.chat_message.message_type === ChatMessageMessageType.EVENT &&
            (body.chat_message.event.name === ChatMessageEventName.APPOINTMENT_RESCHEDULED ||
                body.chat_message.event.name === ChatMessageEventName.APPOINTMENT_MPF_PAID ||
                body.chat_message.event.name === ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED ||
                body.chat_message.event.name === ChatMessageEventName.APPOINTMENT_EPF_SCHEDULED ||
                body.chat_message.event.name ===
                    ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED);

        // 4.1. Dźwięk - emitujemy jeżeli wiadomość:
        // - kwalifikuje się do powiadomienia (jest od usera innego niż nasz i nie jest zdarzeniem)
        // - użytkownik nie jest aktualnie na danym czacie
        // - użytkownik nie uczestniczy aktualnie w wideorozmowie

        if (
            MESSAGE_FROM_USER_DIFFERENT_THAN_LOGGED_IN &&
            (NOT_EVENT_MESSAGE || ALLOWED_EVENT_MESSAGE) &&
            THUSAPPRCHAT_MESSAGE &&
            !general_store.isVideoCallActive() &&
            (!chats_store.isChatActive(body.chat_message.chat) || !(await appIsFocused()))
        ) {
            playNewNotificationSound();
        }

        // 4.2. Tile z notyfikacją
        if (
            MESSAGE_FROM_USER_DIFFERENT_THAN_LOGGED_IN &&
            (NOT_EVENT_MESSAGE || ALLOWED_EVENT_MESSAGE) &&
            THUSAPPRCHAT_MESSAGE &&
            !chats_store.isChatActive(body.chat_message.chat) &&
            !general_store.isVideoCallActive()
        ) {
            // 4.2.1. Jeśli istnieje już powiadomienie dla tego czatu, to je usuwamy
            if (new_chat_message_ui_notifications.has(body.chat_message.chat)) {
                new_chat_message_ui_notifications.get(body.chat_message.chat)?.destroy();
            }

            new_chat_message_ui_notifications.set(
                body.chat_message.chat,
                displayAlert.info({
                    props: {
                        notification_type: NotificationType.NEW_CHAT_MESSAGE,
                        chat_message: body.chat_message
                    } as NewChatMessageUIAlertProps,
                    duration: Infinity
                })
            );
        }
    });

    IO.on(IoEvent.CHAT_MESSAGE_DELETED, async (body: IoDeletedChatMessagePayload) => {
        // console.log("[Socket.io] ChatMessage.deleted", body);
        const chats_store = useChatsStore();
        chats_store.deleteChatMessage(body.chat_message);
    });

    IO.on(IoEvent.APPOINTMENT_CREATED, async (body: IoAppointmentPayload) => {
        const fetched_elements = useFetchedElementsStore();
        fetched_elements.updateOrInsertAppointment(body.appointment);
    });
    IO.on(IoEvent.APPOINTMENT_UPDATED, async (body: IoAppointmentPayload) => {
        const fetched_elements = useFetchedElementsStore();
        fetched_elements.updateOrInsertAppointment(body.appointment);
    });
    IO.on(
        IoEvent.APPOINTMENT_RESCHEDULE_PROPOSAL_UPDATED,
        async (body: IoAppointmentRescheduleProposalPayload) => {
            const fetched_elements = useFetchedElementsStore();
            fetched_elements.updateOrInsertAppointmentRescheduleProposal(
                body.appointment_reschedule_proposal
            );
        }
    );

    console.log("[Socket.io] Events inited");
    socket_events_inited = true;
}

export async function authorizeInSocketIo() {
    if (socket_logged_in) return;

    const authStore = useAuthStore();
    if (!authStore.auth_data) {
        console.log(`[Socket.io] Missing auth_data - login supressed`);
        return;
    }

    if (!authStore.isAuthDataValid(authStore.auth_data)) {
        console.log(`[Socket.io] Invalid auth_data - login supressed`);
        return;
    }

    if (authStore.auth_data.access_token_exp_date - 10 * 1000 < Date.now()) {
        console.log(`[Socket.io] Expired access_token - login supressed`);
        return;
    }

    socket_login_tries += 1;

    IO.emit(
        "login",
        {
            access_token: `Bearer ${authStore.auth_data.access_token}`
        },
        (res: ApiSocketGeneralResponse) => {
            if (res.success) {
                socket_login_tries = 0;
                socket_logged_in = true;
                console.log(res.msg);
            } else {
                console.log(res.msg);
                if (socket_login_tries < 50) {
                    console.log("[Socket.io] Delaying re-auth for 5s");
                    setTimeout(authorizeInSocketIo, 5000);
                }
            }
        }
    );
}

export function joinPredefinedRoom(room: IoPredefinedRooms) {
    return new Promise((resolve, reject) => {
        try {
            IO.emit(
                IoEmit.JOIN_ROOM,
                {
                    room
                },
                (e: ApiSocketGeneralResponse) => {
                    if (e.success === true) {
                        JOINED_ROOMS.add(room);
                    }
                    console.log(e.msg);
                    return resolve(e);
                }
            );
        } catch (err) {
            return reject(err);
        }
    });
}
export function leavePredefinedRoom(room: IoPredefinedRooms) {
    return new Promise((resolve, reject) => {
        try {
            IO.emit(
                IoEmit.LEAVE_ROOM,
                {
                    room
                },
                (e: ApiSocketGeneralResponse) => {
                    JOINED_ROOMS.delete(room);
                    console.log(e.msg);
                    return resolve(e);
                }
            );
        } catch (err) {
            return reject(err);
        }
    });
}
