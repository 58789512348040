function playSound(audio_path: string, volume: number = 1) {
    try {
        const a = new Audio(audio_path);
        a.volume = volume;
        a.play();
    } catch (err) {
        console.error(err);
    }
}

import temp_sound from "../assets/temp_sound.mp3";
export function playNewNotificationSound(vol = 1) {
    playSound(temp_sound, vol);
}

import vc_rpc_sound from "../assets/audio/risify-call-connect.mp3";
export function playVideocallRemoteParticipantConnectSound(vol: number = 1) {
    playSound(vc_rpc_sound, vol);
}

import vc_dc_sound from "../assets/audio/risify-call-disconnect.mp3";
export function playVideocallDisconnectSound(vol: number = 1) {
    playSound(vc_dc_sound, vol);
}
