<template>
    <div>
        <Dialog
            v-model="props.modelValue"
            persistent
            width="720"
        >
            <template v-slot:header>
                <DialogBaseHeader
                    close-button
                    @close="
                        resetFilledData();
                        emit('update:modelValue', false);
                    "
                >
                    Dodaj nową wizytę
                </DialogBaseHeader>
            </template>
            <template v-slot:default>
                <DialogBaseBody>
                    <!--PRODUCT-->
                    <RisifySelect
                        outlined
                        v-model="appointment_product"
                        :items="available_products"
                        show-asterisk
                        class-name="mb-4 mb-md-6"
                        label="Usługa"
                        placeholder="Wybierz usługę"
                    >
                        <template #selection="{ slotProps: { items } }">
                            {{ items[0].product.name }}
                            <span
                                v-if="items[0].product.type === ProductType.APPOINTMENT"
                                class="text-weight-regular text-link-2"
                            >
                                (j.
                                {{
                                    TherapySessionLanguageLabel[
                                        items[0].product.appointment.therapy_session.lang
                                    ]
                                }})
                            </span>
                        </template>
                        <template #item="{ slotProps: { item } }">
                            <span class="text-body-1 text-weight-semibold">
                                {{ item.product.name }}
                            </span>
                            <span
                                v-if="item.product.type === ProductType.APPOINTMENT"
                                class="text-link-2"
                            >
                                (j.
                                {{
                                    TherapySessionLanguageLabel[
                                        item.product.appointment.therapy_session.lang
                                    ]
                                }})
                            </span>
                        </template>
                    </RisifySelect>

                    <!--OWNER-->
                    <UserPicker
                        v-model="appointment_owner"
                        data-type="users"
                        source-url="/users/me/therapy-sessions-partcipants"
                        search-param="filter_name"
                        search-label="Wyszukaj pacjenta"
                        label="Pacjent"
                        class="mb-4 mb-md-6"
                        ref="appointment_owner_ref"
                        @update:model-value="v => removeParticipant(v._id)"
                        v-if="appointment_product"
                    >
                        <template v-slot:activator>
                            <RisifyTextField
                                v-model="appointment_owner_full_name"
                                label="Pacjent"
                                placeholder="Nie wybrano"
                                show-asterisk
                                outlined
                                readonly
                                @click="openOwnerSelectDialog"
                            >
                                <template
                                    v-slot:prepend
                                    v-if="appointment_owner"
                                >
                                    <Avatar
                                        :size="24"
                                        :image-src="appointment_owner.profile_image?.url_xs"
                                    />
                                </template>
                                <template v-slot:append>
                                    <RisifyButton
                                        color="orange"
                                        text
                                        @click="openOwnerSelectDialog"
                                    >
                                        <CustomIcon
                                            name="pencil"
                                            size="20"
                                            class="mr-2"
                                        />
                                        <span class="text-label-1"> Zmień </span>
                                    </RisifyButton>
                                </template>
                            </RisifyTextField>
                        </template>
                    </UserPicker>

                    <!--PARTICIPANTS-->
                    <div
                        v-if="
                            appointment_owner &&
                            appointment_product_participants_limit &&
                            appointment_product_participants_limit > 1
                        "
                        class="mb-4 mb-md-6"
                    >
                        <div class="text-link-2 mb-4">
                            Pozostali użytkownicy ({{ appointment_participants.length }}/{{
                                appointment_product_participants_limit - 1
                            }})
                        </div>
                        <UserTile
                            v-for="part in appointment_participants"
                            :key="part._id"
                            :profile-image="part.profile_image"
                            :name="part.first_name + ' ' + part.last_name"
                            text="Pacjent"
                            class="mb-2"
                            dense
                            action-button
                        >
                            <template v-slot:action-slot>
                                <RisifyButton
                                    color="red"
                                    text
                                    small
                                    @click="removeParticipant(part._id)"
                                >
                                    <CustomIcon
                                        name="trash"
                                        size="16"
                                    />
                                </RisifyButton>
                            </template>
                        </UserTile>
                        <UserPicker
                            v-model="appointment_new_participant"
                            data-type="users"
                            source-url="/users/me/therapy-sessions-partcipants"
                            search-param="filter_name"
                            :key="appointment_owner?._id"
                            :filter-ids="appointment_owner ? [appointment_owner._id] : []"
                            search-label="Wyszukaj pacjenta"
                            dialog-title="Dodaj uczestnika"
                            dense
                            @update:model-value="addParticipant"
                            v-if="
                                appointment_participants.length <
                                appointment_product_participants_limit - 1
                            "
                        >
                            <template v-slot:activator>
                                <RisifyButton
                                    small
                                    text
                                    color="green"
                                    title="Usuń uczestnika"
                                >
                                    <CustomIcon
                                        name="plus-circle"
                                        class="mr-1"
                                    />
                                    Dodaj
                                </RisifyButton>
                            </template>
                        </UserPicker>
                    </div>

                    <!--DATE-->
                    <div
                        v-if="
                            appointment_product &&
                            appointment_owner &&
                            auth_store.user &&
                            appointment_product_duration
                        "
                    >
                        <AppointmentSimpleSlotOrDatetimePicker
                            :product="appointment_product"
                            :therapist="auth_store.user._id"
                            :duration="appointment_product_duration"
                            ref="date_selector_ref"
                            :key="appointment_product"
                            :start-time="props.startTime"
                        />
                        <AppointmentPaymentDeadlinePicker
                            ref="appointment_payment_deadline_picker_ref"
                            class="mt-4"
                        />
                    </div>
                </DialogBaseBody>
            </template>
            <template v-slot:footer>
                <DialogBaseFooter>
                    <RisifyButton
                        color="yellow"
                        class="ml-auto"
                        @click="createAppointment"
                        :loading="loading"
                    >
                        Umów wizytę
                    </RisifyButton>
                </DialogBaseFooter>
            </template>
        </Dialog>
    </div>
    <Dialog
        v-model="conflicting_appointments_dialog"
        persistent
        width="540"
    >
        <template v-slot:header>
            <DialogBaseHeader> Kolizja terminów </DialogBaseHeader>
        </template>
        <template v-slot:default>
            <DialogBaseBody>
                <div class="mb-4">
                    W tym przedziale czasowym masz już
                    {{
                        pluralize(conflicting_appointments.length, [
                            "_",
                            "zarezerwowaną inną sesję terapeutyczną",
                            "zarezerwowane {n} inne sesje terapeutyczne",
                            "zarezerwowane {n} innych sesji terapeutycznych"
                        ])
                    }}:
                </div>
                <div
                    v-for="app in conflicting_appointments"
                    :key="app._id"
                >
                    <div class="cad-collision-tile mt-2 pa-2">
                        <div class="text-body-1 text-weight-semibold">{{ app.product.name }}</div>
                        <div class="text-body-2">
                            {{ formatDate(app.start_time, "H:i") }} -
                            {{ formatDate(app.end_time, "H:i") }}
                        </div>
                        <div
                            class="text-body-2"
                            v-if="appointment_owner"
                        >
                            z {{ appointment_owner_full_name }} ({{ appointment_owner.email }})
                        </div>
                    </div>
                </div>
                <div class="mt-4">Czy mimo wszystko chcesz zaplanować tę wizytę?</div>
            </DialogBaseBody>
        </template>
        <template v-slot:footer>
            <DialogBaseFooter class="cad-collision-dialog__footer">
                <RisifyButton
                    color="dark-green"
                    text
                    @click="conflicting_appointments_dialog = false"
                    class="mb-1 mb-sm-0"
                >
                    Nie umawiaj
                </RisifyButton>
                <RisifyButton
                    color="yellow"
                    class="ml-auto mb-4 mb-sm-0"
                    @click="
                        () => {
                            collision_allowed = true;
                            createAppointment();
                        }
                    "
                    :loading="loading"
                >
                    Umów wizytę mimo kolizji
                </RisifyButton>
            </DialogBaseFooter>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
/*###############
### IMPORTS ###
###############*/
import moment from "@/helpers/moment";

// Modules
import { ref, computed, onMounted } from "vue";
import { displayAlert } from "@/main";
import { httpJsonRequest } from "@/plugins/fetchApi";
import { useFetchedElementsStore } from "@/stores/fetched-elements";
import { useAuthStore } from "@/stores/auth";

// Components
import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import Avatar from "@/components/generics/Avatar.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import UserTile from "@/components/tiles/UserTile.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import RisifySelect from "@/components/form-inputs/RisifySelect.vue";
import RisifyTextField from "@/components/form-inputs/RisifyTextField.vue";
import UserPicker from "@/components/pickers/UserPicker.vue";
import AppointmentSimpleSlotOrDatetimePicker, {
    AppointmentSimpleSlotOrDatetimePickerExpose
} from "@/components/pickers/AppointmentSimpleSlotOrDatetimePicker.vue";
import AppointmentPaymentDeadlinePicker, {
    AppointmentPaymentDeadlineExpose
} from "@/components/pickers/AppointmentPaymentDeadlinePicker.vue";

// Types
import {
    Appointment,
    ApiAppointmentResponse,
    ApiAppointmentsResponse,
    AppointmentStatus
} from "@/types/appointment";
import { UserRole, UserPublicDataFragment, UserPublicDataWithContactInfo } from "@/types/user";
import { ProductType } from "@/types/product";
import { TherapySessionLanguageLabel } from "@/types/general";
import { useGeneralStore } from "@/stores/general";

// Helpers
import { formatDate } from "@/helpers/formatters";
import { pluralize } from "@/helpers/pluralizer";

/*###########
### SETUP ###
###########*/
type CreateAppointmentDialogProps = {
    modelValue: boolean;
    product?: string;
    owner?: UserPublicDataFragment;
    participants?: UserPublicDataFragment[];
    startTime?: string;
};
const props = withDefaults(defineProps<CreateAppointmentDialogProps>(), {
    modelValue: false
});

const emit = defineEmits<{
    (e: "update:modelValue", v: boolean): void;
    (e: "appointment-created", v: Appointment): void;
}>();

const fetched_elements_store = useFetchedElementsStore();
const auth_store = useAuthStore();
const general_store = useGeneralStore();

/*##########
### DATA ###
##########*/

// Globals
const loading = ref(false);

// Variables
const appointment_owner = ref<UserPublicDataWithContactInfo>();
const appointment_product = ref<string>("");
const appointment_participants = ref<UserPublicDataFragment[]>([]);
const appointment_new_participant = ref<UserPublicDataFragment>();

const conflicting_appointments = ref<Appointment[]>([]);
const conflicting_appointments_dialog = ref(false);
const collision_allowed = ref(false);

// Refs
const appointment_owner_ref = ref<typeof UserPicker>();
const appointment_payment_deadline_picker_ref = ref<AppointmentPaymentDeadlineExpose>();
const date_selector_ref = ref<AppointmentSimpleSlotOrDatetimePickerExpose>();

/*##############
### COMPUTED ###
##############*/

const available_products = computed(() => {
    if (auth_store.user?.role !== UserRole.THERAPIST) return [];

    return Object.values(auth_store.user.therapist_assigned_products)
        .filter(
            e =>
                e.enabled === true &&
                !general_store.THERAPIST_EXCLUDED_PRODUCTS.includes(e.product._id)
        )
        .map(e => {
            return {
                text: e.product.name,
                value: e.product._id,
                product: e.product
            };
        });
});

const appointment_owner_full_name = computed(() => {
    return appointment_owner.value
        ? `${appointment_owner.value.first_name} ${appointment_owner.value.last_name}`
        : "";
});

const appointment_product_duration = computed(() => {
    if (auth_store.user?.role !== UserRole.THERAPIST) return null;

    const products = Object.values(auth_store.user.therapist_assigned_products);

    const ix = products.findIndex(it => it.product._id === appointment_product.value);
    return ix === -1 ? null : products[ix].product.appointment.duration;
});

const appointment_product_participants_limit = computed(() => {
    if (auth_store.user?.role !== UserRole.THERAPIST) return 1;

    const products = Object.values(auth_store.user.therapist_assigned_products);

    const ix = products.findIndex(it => it.product._id === appointment_product.value);
    return ix === -1 ? 1 : products[ix].product.appointment.participant_count;
});

/*#############
### METHODS ###
#############*/

function addParticipant() {
    if (!appointment_new_participant.value) return;

    appointment_participants.value.push(appointment_new_participant.value);

    appointment_new_participant.value = undefined;
}

function removeParticipant(id: string) {
    const ix = appointment_participants.value.findIndex(el => el._id == id);
    if (ix != -1) appointment_participants.value.splice(ix, 1);
}

function openOwnerSelectDialog() {
    return appointment_owner_ref.value?.openDialog();
}

function resetFilledData() {
    appointment_owner.value = undefined;
    appointment_product.value = "";
    appointment_participants.value = [];
    appointment_new_participant.value = undefined;
    conflicting_appointments.value = [];
    collision_allowed.value = false;
}

async function createAppointment() {
    if (loading.value || date_selector_ref.value === undefined || !appointment_product.value)
        return;

    if (!appointment_owner.value) {
        displayAlert.error({
            title: "Niekompletne dane",
            message: "Proszę wybrać pacjenta"
        });
        return;
    }
    if (!appointment_product.value) {
        displayAlert.error({
            title: "Niekompletne dane",
            message: "Proszę wybrać usługę"
        });
        return;
    }
    if (appointment_product_participants_limit.value > 1) {
        if (
            appointment_participants.value.length >
            appointment_product_participants_limit.value - 1
        ) {
            displayAlert.error({
                title: "Nieprawidłowe dane",
                message: `Maksymalna liczba dodatkowych uczestników: ${appointment_product_participants_limit.value}`
            });
            return;
        }
    } else {
        appointment_participants.value = [];
    }

    const A: boolean[] = [
        date_selector_ref.value ? date_selector_ref.value.validate() : false,
        appointment_payment_deadline_picker_ref.value
            ? appointment_payment_deadline_picker_ref.value.validate()
            : false
    ];

    if (A.indexOf(false) !== -1) {
        return;
    }

    loading.value = true;

    const AB: Record<string, any> = {};

    AB.product = appointment_product.value;
    AB.host = auth_store.user?._id;

    const value = date_selector_ref.value.getValue();

    if (value.type === "slot") {
        AB.slot_id = value.slot_id;
    } else if (value.type === "time-range") {
        AB.start_date_time = value.date_time_from;
        AB.end_date_time = value.date_time_to;
    }

    AB.participants = [
        appointment_owner.value._id,
        ...appointment_participants.value.map(el => el._id)
    ];

    AB.owner = appointment_owner.value._id;

    const PD = appointment_payment_deadline_picker_ref.value?.getData();
    if (PD) {
        AB.payment_deadline = PD;
    }

    // tylko w trybie 'poza kalendarzem'
    if (value.type === "time-range" && !collision_allowed.value) {
        // pobieramy wizyty w okolicach nowo planowanej wizyty
        try {
            const res = await httpJsonRequest<ApiAppointmentsResponse>(
                `/appointments/?filter_start_time_from=${moment(AB.start_date_time)
                    .subtract(4, "hours")
                    .toISOString()}&filter_start_time_to=${moment(AB.end_date_time)
                    .add(4, "hours")
                    .toISOString()}`
            );

            if (res.appointments.length > 0) {
                // sprawdzamy czy potencjalne, kolidujące wizyty nie są anulowane
                const not_canceled_appointments = res.appointments.filter(
                    a => a.status !== AppointmentStatus.CANCELED
                );
                // sprawdzamy czy potencjalny, kolidujące wizyty pokrywają się (częściowo lub całościowo) z planowaną nową wizytą z uwzględnieniem, że jeśli nowo planowana wizyta zaczyna się o tej samej godzinie co kończy się inna wizyta lub kończy się o tej samej godzinie co zaczyna inna wizyta to nie jest to kolizja
                conflicting_appointments.value = not_canceled_appointments.filter(
                    a =>
                        moment(a.start_time).isBefore(AB.end_date_time) &&
                        moment(a.end_time).isAfter(AB.start_date_time)
                );

                if (conflicting_appointments.value.length > 0) {
                    conflicting_appointments_dialog.value = true;
                    loading.value = false;
                    return;
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    try {
        const res = await httpJsonRequest<ApiAppointmentResponse>("/appointments", {
            method: "POST",
            body: JSON.stringify(AB)
        });

        fetched_elements_store.updateOrInsertAppointment(res.appointment);
        emit("appointment-created", res.appointment);
        displayAlert.success({
            title: "Wizyta umówiona",
            message: "Wizyta została umówiona pomyślnie.",
            duration: 5000
        });

        emit("update:modelValue", false);
        if (conflicting_appointments_dialog.value) {
            conflicting_appointments_dialog.value = false;
        }
    } catch (e) {
        console.error(e);
    }

    collision_allowed.value = false;
    loading.value = false;
    resetFilledData();
}

/*#############
### HOOKS ###
#############*/

onMounted(() => {
    if (props.product) appointment_product.value = props.product;
    if (props.owner) appointment_owner.value = props.owner;
    if (props.participants) appointment_participants.value = props.participants;
});
</script>
