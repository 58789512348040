<template>
    <div class="survey-questions">
        <div
            class="survey-questions__question"
            v-for="entry in survey.questions"
            :key="entry._id"
        >
            <SurveyQuestionSingleChoice
                v-if="entry.type === SurveyQuestionType.SINGLE_CHOICE"
                :question="entry"
                ref="questions_refs"
            />
            <SurveyQuestionMultipleChoice
                v-else-if="entry.type === SurveyQuestionType.MULTIPLE_CHOICE"
                :question="entry"
                ref="questions_refs"
            />
            <SurveyQuestionText
                v-else-if="entry.type === SurveyQuestionType.TEXT"
                :question="entry"
                ref="questions_refs"
            />
            <SurveyQuestionNps
                v-else-if="entry.type === SurveyQuestionType.NPS"
                :question="entry"
                ref="questions_refs"
            />
            <SurveyQuestionSfdb
                v-else-if="entry.type === SurveyQuestionType.SIMPLE_FEEDBACK"
                :question="entry"
                ref="questions_refs"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SurveyQuestionSingleChoice from "@/modules/survey/SurveyQuestionSingleChoice.vue";
import SurveyQuestionMultipleChoice from "@/modules/survey/SurveyQuestionMultipleChoice.vue";
import SurveyQuestionText from "@/modules/survey/SurveyQuestionText.vue";
import SurveyQuestionNps from "@/modules/survey/SurveyQuestionNps.vue";
import SurveyQuestionSfdb from "@/modules/survey/SurveyQuestionSfdb.vue";
import { Survey, SurveyQuestionExpose, SurveyQuestionValue, SurveyQuestionType } from "@/types/surveys";

export type SurveyQuestionsExpose = {
    getValue: () => SurveyQuestionValue[];
    validate: () => { validation_status: boolean; first_error_anchor?: HTMLElement | undefined };
};

defineProps<{
    survey: Survey;
}>();

defineExpose<SurveyQuestionsExpose>({
    getValue,
    validate
});

const questions_refs = ref<SurveyQuestionExpose[]>([]);

function getValue(): SurveyQuestionValue[] {
    const data: SurveyQuestionValue[] = [];
    questions_refs.value.forEach(question => {
        const value = question.getValue();
        if (value) {
            data.push(value);
        }
    });
    return data;
}

function validate() {
    const A = questions_refs.value.map(question => question.validate());
    if (A.indexOf(false) !== -1) {
        return {
            validation_status: false,
            first_error_anchor: questions_refs.value[A.indexOf(false)].getAnchorElement()
        };
    }
    return {
        validation_status: true
    };
}
</script>
