<template>
    <div
        class="risify-input"
        :class="{
            'risify-input--dense': dense,
            ...custom_classes
        }"
    >
        <label
            :for="id"
            class="risify-input__label text-link-2"
            v-if="hasSlot('label') || (label !== undefined && label !== '')"
            :style="additional_label_and_message_styles"
        >
            <div class="risify-input__label-content">
                <slot
                    name="label"
                    v-if="hasSlot('label')"
                ></slot>
                <template v-else-if="label !== undefined && label !== ''">
                    {{ label }}
                </template>
            </div>
            <span
                class="risify-input__asterisk"
                v-if="showAsterisk"
            >
                *
            </span>
        </label>
        <div class="risify-input__grid">
            <div
                class="risify-input__prepend-outer"
                v-if="hasSlot('prepend-outer')"
                @click.stop="e => emit('click:prepend-outer', e)"
                ref="prepend_outer"
            >
                <slot name="prepend-outer"></slot>
            </div>
            <div
                class="risify-input__main"
                ref="main"
            >
                <slot></slot>
            </div>
            <div
                class="risify-input__append-outer"
                v-if="hasSlot('append-outer')"
                @click.stop="e => emit('click:append-outer', e)"
            >
                <slot name="append-outer"></slot>
            </div>
        </div>
        <div
            class="risify-input__messages text-link-3"
            :style="additional_label_and_message_styles"
            v-if="
                (!error && (hasSlot('hint') || (hint !== undefined && hint !== ''))) ||
                (error && msg !== '') ||
                counter_value !== false
            "
        >
            <transition
                name="fade"
                mode="out-in"
                appear
            >
                <div
                    class="risify-input__message risify-input__hint"
                    v-if="!error && (hasSlot('hint') || (hint !== undefined && hint !== ''))"
                    key="hint"
                >
                    <slot
                        name="hint"
                        v-if="hasSlot('hint')"
                    ></slot>
                    <template v-else>
                        {{ hint }}
                    </template>
                </div>
                <div
                    class="risify-input__message risify-input__errormsg"
                    v-else-if="error && msg != '' && !disabled"
                    key="msg"
                >
                    {{ msg }}
                </div>
            </transition>
            <div
                class="risify-input__counter"
                v-if="counter_value !== false"
            >
                {{ value_length }}/{{ counter_value }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T">
import { ref, computed, onUpdated, useSlots, watch, onMounted } from "vue";
import { useWindowSize } from "@vueuse/core";

export type RisifyInputValidationRule<T> = ((v: T) => string | boolean) | string | boolean;

export type RisifyInputProps<T> = {
    id?: string;
    counter?: number | string;
    hint?: string;
    label?: string;
    rules?: RisifyInputValidationRule<T>[];
    value: T;
    showAsterisk?: boolean;
    error?: boolean;
    className?: string;
    disabled?: boolean;
    dense?: boolean;
};

export type RisifyInputBaseExpose = {
    validate: () => boolean;
    resetValidation: () => void;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifyInputProps<T>>(), {
    rules: () => [],
    showAsterisk: false,
    error: false
});

const emit = defineEmits<{
    (e: "update:error", error: boolean): void;
    (e: "click:prepend-outer", ev: MouseEvent | PointerEvent): void;
    (e: "click:append-outer", ev: MouseEvent | PointerEvent): void;
}>();

defineExpose({
    validate,
    resetValidation
});

defineOptions({
    inheritAttrs: false
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const msg = ref("");
const main = ref<null | HTMLElement>(null);
const main_width = ref(100);
const prepend_outer = ref<null | HTMLElement>(null);
const prepend_outer_width = ref<number | string>("auto");
const { width } = useWindowSize();

/*##############
### COMPUTED ###
##############*/
const custom_classes = computed(() => {
    if (props.className) {
        return { [props.className]: true };
    }
    return {};
});

const additional_label_and_message_styles = computed(() => {
    const STYLES: Record<string, string> = {};

    if (hasSlot("prepend-outer") || hasSlot("append-outer")) {
        STYLES["max-width"] = main_width.value + "px";
    }

    if (hasSlot("prepend-outer")) {
        STYLES["margin-left"] =
            prepend_outer_width.value === "auto" ? "auto" : prepend_outer_width.value + "px";
    }

    return STYLES;
});

const value_length = computed(() => {
    if (typeof props.value === "string" || typeof props.value === "number") {
        return props.value.toString().length;
    }
    return "0";
});

const counter_value = computed(() => {
    if (typeof props.value !== "string" && typeof props.value !== "number") return false;

    if (typeof props.counter == "number" && props.counter > 0) {
        return props.counter;
    } else if (typeof props.counter == "string") {
        const pn = parseInt(props.counter);
        if (!isNaN(pn)) return pn;
    }
    return false;
});

/*##############
### WATCHERS ###
##############*/
watch(width, () => calcMainWidth, { immediate: true });

/*#############
### METHODS ###
#############*/
function hasSlot(name: string) {
    return !!slots[name];
}

function validate() {
    let invalid = false;
    let validation_msg: null | string = null;

    for (let i = 0; i < props.rules.length; i++) {
        const RULE = props.rules[i];
        if (typeof RULE === "function") {
            const r = RULE(props.value);
            if (r !== true) {
                invalid = true;
                if (typeof r == "string") validation_msg = r;
                break;
            }
        } else if (typeof RULE === "boolean") {
            if (RULE === false) {
                invalid = true;
                break;
            }
        } else if (typeof RULE === "string") {
            invalid = true;
            validation_msg = RULE;
            break;
        }
    }

    emit("update:error", invalid);
    if (invalid) {
        if (validation_msg != null) msg.value = validation_msg;
    } else {
        msg.value = "";
    }

    return !invalid;
}

function resetValidation() {
    emit("update:error", false);
}

function calcMainWidth() {
    if (main.value == null) return;
    main_width.value = main.value.offsetWidth;
    if (prepend_outer.value == null) return;
    prepend_outer_width.value = hasSlot("prepend-outer") ? prepend_outer.value.offsetWidth : "auto";
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onUpdated(() => {
    calcMainWidth();
});
onMounted(() => {
    calcMainWidth();
});
</script>
