<template>
    <g clip-path="url(#clip0_585_4224)">
        <mask
            id="mask0_585_4224"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" />
        </mask>
        <g mask="url(#mask0_585_4224)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.2422 22.5938C13.1607 22.5938 10.5906 20.3985 10.0127 17.4861L8.6333 17.7599C9.33934 21.3176 12.4765 24 16.2422 24C17.6826 24 19.0327 23.6065 20.1894 22.9216L23.9662 23.9662L22.9216 20.1894C23.6065 19.0327 24 17.6826 24 16.2422C24 12.4765 21.3176 9.33933 17.7599 8.6333L17.4861 10.0126C20.3985 10.5906 22.5938 13.1607 22.5938 16.2422C22.5938 17.5176 22.2183 18.7031 21.5723 19.6973L21.3998 19.9628L21.9493 21.9493L19.9628 21.3998L19.6973 21.5723C18.7031 22.2183 17.5176 22.5938 16.2422 22.5938Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.16406 16.9219C7.62703 16.9219 6.19645 16.4755 4.99208 15.7057L4.72801 15.5369L2.05072 16.2774L2.79124 13.6001L2.62245 13.336C1.85265 12.1317 1.40625 10.7011 1.40625 9.16406C1.40625 4.87956 4.87956 1.40625 9.16406 1.40625C13.4486 1.40625 16.9219 4.87956 16.9219 9.16406C16.9219 13.4486 13.4486 16.9219 9.16406 16.9219ZM4.50593 17.0574C5.87134 17.8647 7.46448 18.3281 9.16406 18.3281C14.2252 18.3281 18.3281 14.2252 18.3281 9.16406C18.3281 4.10291 14.2252 1.90735e-06 9.16406 1.90735e-06C4.10291 1.90735e-06 0 4.10291 0 9.16406C0 10.8636 0.463419 12.4568 1.27077 13.8222L0.0338072 18.2943L4.50593 17.0574Z"
            />
            <path d="M9.86719 14.1094H8.46094V12.7031H9.86719V14.1094Z" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.86719 10.2002V11.2969H8.46094V9.58101L10.1126 8.06922C10.3948 7.8111 10.5703 7.44206 10.5703 7.03125C10.5703 6.25459 9.94072 5.625 9.16406 5.625C8.3874 5.625 7.75781 6.25459 7.75781 7.03125H6.35156C6.35156 5.47794 7.61075 4.21875 9.16406 4.21875C10.7174 4.21875 11.9766 5.47794 11.9766 7.03125C11.9766 7.85317 11.623 8.59337 11.0621 9.10653C11.0621 9.10648 11.062 9.10659 11.0621 9.10653L9.86719 10.2002Z"
            />
        </g>
    </g>
</template>
