import { useWindowSize } from "@vueuse/core";
import { computed, ref, watch } from "vue";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

export function useBreakpoints() {
    const { width } = useWindowSize();
    const bp = ref<Breakpoint>("xs");

    function detectBp(screen_width: number) {
        if (screen_width < 600) {
            bp.value = "xs";
        } else if (screen_width < 960) {
            bp.value = "sm";
        } else if (screen_width < 1280) {
            bp.value = "md";
        } else if (screen_width < 1920) {
            bp.value = "lg";
        } else {
            bp.value = "xl";
        }
    }

    const is_mobile = computed(() => bp.value === "xs" || bp.value === "sm");
    const is_desktop = computed(() => bp.value === "md" || bp.value === "lg" || bp.value == "xl");

    watch(
        width,
        () => {
            detectBp(width.value);
        },
        { immediate: true }
    );

    return { breakpoint: bp, is_mobile, is_desktop };
}
