import { createI18n, useI18n as useI18nOriginal, I18nOptions, UseI18nOptions } from "vue-i18n";

import pl from "../locales/pl.json";
import es from "../locales/es.json";
import { Capacitor } from "@capacitor/core";
import { polishPluralizeResolver } from "@/helpers/pluralizer";

type MessagesType = typeof pl;
type LocalesType = "pl" | "es";

function determineStartLocale() {
    let loc: LocalesType = "pl";

    if (!Capacitor.isNativePlatform() && window.location) {
        const HOSTNAME = window.location.hostname;
        if (
            (import.meta.env.MODE === "development" && /[^a-z0-9]es[^a-z0-9]/i.test(HOSTNAME)) ||
            /\.es$/.test(HOSTNAME)
        ) {
            loc = "es";
        }
    }

    return loc;
}

function getCreateI18nOptions() {
    const OPTS: I18nOptions<{ message: MessagesType }, LocalesType> = {
        legacy: false,
        locale: determineStartLocale(),
        fallbackLocale: "pl",
        pluralRules: {
            pl: polishPluralizeResolver
        },
        messages: {
            pl,
            es
        },
        globalInjection: false
    };

    return OPTS;
}

export const i18n = createI18n<[MessagesType], LocalesType>(getCreateI18nOptions());

export function usei18n(opts: UseI18nOptions<{ message: MessagesType }, LocalesType> = {}) {
    return useI18nOriginal<[MessagesType], LocalesType>(opts);
}
