<template>
    <div class="risify-date-picker">
        <div class="risify-date-picker__tfield">
            <RisifyTextField
                :model-value="date_picker_value_datetime"
                :prefix="formatted_date_picker_value_datetime"
                :label="label"
                :rules="rules"
                :show-asterisk="showAsterisk"
                :outlined="outlined"
                :dense="dense"
                :hint="hint"
                :placeholder="placeholder"
                suffix=""
                readonly
                @click="openDatePicker"
                @click:append="openDatePicker"
                ref="custom_dp_tfield_ref"
                :disabled="disabled"
            >
                <template #append>
                    <CustomIcon
                        name="calendar"
                        size="16"
                    />
                </template>
                <template
                    #hint
                    v-if="timezone"
                >
                    <div style="display: flex; align-items: center">
                        <CustomIcon
                            name="clock"
                            size="12"
                            class="mr-1"
                        ></CustomIcon>
                        {{ timezone }}
                    </div>
                </template>
            </RisifyTextField>
        </div>
        <UniversalDatePicker
            v-model="date_picker_value_datetime"
            @update:model-value="handleUdpUpdate"
            v-model:visible="date_picker_visible_datetime"
            :mode="mode"
            :min-date="minDate"
            :max-date="maxDate"
            :min-time="minTime"
            :max-time="maxTime"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, watch } from "vue";

import CustomIcon from "@/components/generics/CustomIcon.vue";
import UniversalDatePicker from "@/components/pickers/UniversalDatePicker.vue";
import RisifyTextField, {
    RisifyTextFieldProps
} from "@/components/form-inputs/RisifyTextField.vue";

import { RisifyInputValidationRule } from "@/components/form-inputs/RisifyInput.vue";

import moment from "@/helpers/moment";

/*###########
### SETUP ###
###########*/

type RisifyDatePickerProps<T> = Omit<
    RisifyTextFieldProps,
    | "modelValue"
    | "rules"
    | "className"
    | "readonly"
    | "counter"
    | "error"
    | "prefix"
    | "suffix"
    | "id"
    | "counter"
    | "name"
> & {
    modelValue: T;
    rules?: RisifyInputValidationRule<T>[];
    mode?: "datetime" | "date" | "time";
    minDate?: string;
    maxDate?: string;
    minTime?: string;
    maxTime?: string;
    timezone?: string;
    dateTimeDisplayFormat?: string;
};

const props = withDefaults(defineProps<RisifyDatePickerProps<string>>(), {
    mode: "datetime",
    showAsterisk: false,
    dateTimeDisplayFormat: "DD.MM.YYYY HH:mm"
});

const emit = defineEmits<{
    (e: "update:modelValue", v: string): void;
}>();

defineExpose({ validate, resetValidation });

/*###############
### VARIABLES ###
###############*/
const date_picker_value_datetime = ref<string>("");
const date_picker_visible_datetime = ref<boolean>(false);

const custom_dp_tfield_ref = ref<typeof RisifyTextField>();

/*##############
### COMPUTED ###
##############*/
const formatted_date_picker_value_datetime = computed(() => {
    if (!date_picker_value_datetime.value) return "";
    if (props.mode == "time") {
        return date_picker_value_datetime.value.slice(0, -3);
    } else {
        let m = moment(date_picker_value_datetime.value);
        return m.format(props.dateTimeDisplayFormat);
    }
});

/*##############
### WATCHERS ###
##############*/
watch(
    () => props.modelValue,
    () => {
        date_picker_value_datetime.value = props.modelValue;
    },
    { immediate: true }
);

/*#############
### METHODS ###
#############*/
function openDatePicker() {
    if (props.disabled) return;

    date_picker_visible_datetime.value = true;
}

function handleUdpUpdate(newValue: string) {
    emit("update:modelValue", newValue);
    nextTick(validate);
}

function validate() {
    return custom_dp_tfield_ref.value ? custom_dp_tfield_ref.value.validate() : false;
}
function resetValidation() {
    if (!custom_dp_tfield_ref.value) return;
    custom_dp_tfield_ref.value.resetValidation();
}
</script>
