<template>
    <RisifySelect
        v-model="appointment_type"
        :items="[
            { value: 'slot', text: 'Termin w ramach kalendarza dostępności' },
            { value: 'time-range', text: 'Termin poza kalendarzem dostępności' }
        ]"
        ref="appointment_type_select_ref"
        label="Rodzaj terminu"
        show-asterisk
        placeholder="Wybierz rodzaj terminu"
        class-name="mb-4 mb-md-6"
        :rules="[isRequired]"
        outlined
        :disabled="loading || disabled"
    ></RisifySelect>
    <AppointmentSimpleSlotPicker
        v-if="appointment_type === 'slot'"
        :product="product"
        :therapist="therapist"
        ref="single_slot_selector_ref"
        outlined
        :loading="loading"
        :disabled="disabled"
    />
    <RisifyDatePicker
        v-if="appointment_type === 'time-range'"
        v-model="appointment_create_dialog_from"
        @update:model-value="autofillAppointmentTo"
        :rules="[isRequired, (v: string) => {
                            if(moment.tz(v, auth_store.getUserTimezone()).isAfter(moment.tz(Date.now(), auth_store.getUserTimezone()))) {
                                return true;
                            } else {
                                return 'Wizyta musi być umówiona na przyszłość';
                            }
                        }]"
        ref="appointment_create_dialog_from_ref"
        :timezone="auth_store.getUserTimezone()"
        class="mb-4"
        label="Początek wizyty"
        show-asterisk
        placeholder="Wybierz datę"
        outlined
        :min-date="moment.tz(Date.now(), auth_store.getUserTimezone()).toISOString()"
        :disabled="disabled || loading"
    />
    <RisifyDatePicker
        v-if="appointment_type === 'time-range' && appointment_create_dialog_from"
        v-model="appointment_create_dialog_to"
        @update:model-value="
            () => {
                if (!appointment_create_dialog_to_touched)
                    appointment_create_dialog_to_touched = true;
            }
        "
        :rules="[isRequired, (v: string) => {
                            if (moment.tz(v, auth_store.getUserTimezone()).isAfter(
                                moment.tz(appointment_create_dialog_from, auth_store.getUserTimezone())
                                    .add(15, 'minutes')
                            )) {
                            return true;
                            } else {
                                return 'Wizyta musi trwać min. 15 minut';  
                            }
                        }, (v: string) => {

                            if (moment.tz(v, auth_store.getUserTimezone()).isBefore(
                                moment.tz(appointment_create_dialog_from, auth_store.getUserTimezone())
                                    .add(360, 'minutes') 
                            )) {
                            return true
                            } else {
                                return 'Wizyta może trwać max. 6 godzin';
                            }
                        }]"
        ref="appointment_create_dialog_to_ref"
        :timezone="auth_store.getUserTimezone()"
        class="mb-4"
        label="Zakończenie wizyty"
        show-asterisk
        placeholder="Wybierz datę"
        outlined
        :min-date="
            moment
                .tz(appointment_create_dialog_from, auth_store.getUserTimezone())
                .add(duration, 'minutes')
                .toISOString()
        "
        :disabled="disabled || loading"
    />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import moment from "@/helpers/moment";

import AppointmentSimpleSlotPicker from "@/components/pickers/AppointmentSimpleSlotPicker.vue";
import RisifyDatePicker from "@/components/pickers/RisifyDatePicker.vue";
import RisifySelect from "@/components/form-inputs/RisifySelect.vue";
import { RisifyInputBaseExpose } from "@/components/form-inputs/RisifyInput.vue";
import { AppointmentSimpleSlotPickerExpose } from "@/components/pickers/AppointmentSimpleSlotPicker.vue";

import { isRequired } from "@/helpers/validators";

/*###########
### SETUP ###
###########*/
interface AppointmentSimpleSlotOrDatetimePickerProps {
    product: string;
    therapist: string;
    duration: number;
    loading?: boolean;
    disabled?: boolean;
    startTime?: string;
}

type AppointmentSimpleSlotOrDatetimePickerValue =
    | { type: "slot"; slot_id: string; date_time_from: string; date_time_to: string }
    | { type: "time-range"; date_time_from: string; date_time_to: string };

export type AppointmentSimpleSlotOrDatetimePickerExpose = {
    getValue: () => AppointmentSimpleSlotOrDatetimePickerValue;
    validate: () => boolean;
};

const auth_store = useAuthStore();
const props = defineProps<AppointmentSimpleSlotOrDatetimePickerProps>();
// TODO: fix types
defineExpose<AppointmentSimpleSlotOrDatetimePickerExpose>({ getValue, validate });

/*###############
### VARIABLES ###
###############*/
const appointment_type = ref<"slot" | "time-range">("slot");
const appointment_type_select_ref = ref<RisifyInputBaseExpose>();

const base_moment_date = moment().tz(auth_store.getUserTimezone());
while (base_moment_date.minute() % 5 !== 0) {
    base_moment_date.minute(base_moment_date.minute() + 1);
}

const appointment_create_dialog_from = ref<string>(base_moment_date.format("YYYY-MM-DDTHH:mm"));
const appointment_create_dialog_from_ref = ref<RisifyInputBaseExpose>();
const appointment_create_dialog_to = ref<string>(
    base_moment_date.add(props.duration, "minutes").format("YYYY-MM-DDTHH:mm")
);
const appointment_create_dialog_to_ref = ref<RisifyInputBaseExpose>();
const appointment_create_dialog_to_touched = ref(false);
const single_slot_selector_ref = ref<AppointmentSimpleSlotPickerExpose>();

/*#############
### METHODS ###
#############*/
// function autofillAppointmentTo() {
//     if (!appointment_create_dialog_to_touched.value && props.appointment) {
//         appointment_create_dialog_to.value = moment
//             .tz(appointment_create_dialog_from.value, auth_store.getUserTimezone())
//             .add(props.duration, "minutes")
//             .toISOString();
//     }
// }
function autofillAppointmentTo() {
    if (!appointment_create_dialog_to_touched.value && props.duration) {
        appointment_create_dialog_to.value = moment
            .tz(appointment_create_dialog_from.value, auth_store.getUserTimezone())
            .add(props.duration, "minutes")
            .format("YYYY-MM-DDTHH:mm");
    }
}

function getValue(): AppointmentSimpleSlotOrDatetimePickerValue {
    if (appointment_type.value === "slot" && single_slot_selector_ref.value) {
        const slot_picker_value = single_slot_selector_ref.value.getValue();
        return {
            type: appointment_type.value,
            slot_id: slot_picker_value.slot_id as string,
            date_time_from: slot_picker_value.date_time_from,
            date_time_to: slot_picker_value.date_time_to
        };
    } else if (appointment_type.value === "time-range") {
        if (
            typeof appointment_create_dialog_from.value === "string" &&
            typeof appointment_create_dialog_to.value === "string"
        ) {
            // return {
            //     type: appointment_type.value,
            //     date_time_from: appointment_create_dialog_from.value,
            //     date_time_to: appointment_create_dialog_to.value
            // };
            return {
                type: appointment_type.value,
                date_time_from: moment
                    .tz(appointment_create_dialog_from.value, auth_store.getUserTimezone())
                    .toISOString(),
                date_time_to: moment
                    .tz(appointment_create_dialog_to.value, auth_store.getUserTimezone())
                    .toISOString()
            };
        }
    }

    throw new Error("Invalid appointment type");
}

function validate() {
    const A: boolean[] = [
        appointment_type_select_ref.value ? appointment_type_select_ref.value.validate() : false
    ];

    single_slot_selector_ref.value && A.push(single_slot_selector_ref.value.validate());

    appointment_create_dialog_from_ref.value &&
        A.push(appointment_create_dialog_from_ref.value.validate());
    appointment_create_dialog_to_ref.value &&
        A.push(appointment_create_dialog_to_ref.value.validate());

    if (A.indexOf(false) !== -1) {
        return false;
    } else {
        return true;
    }
}

onMounted(() => {
    if (props.startTime) {
        appointment_create_dialog_from.value = moment(props.startTime)
            .tz(auth_store.getUserTimezone())
            .format("YYYY-MM-DDTHH:mm");
        appointment_create_dialog_to.value = moment(props.startTime)
            .tz(auth_store.getUserTimezone())
            .add(props.duration, "minutes")
            .format("YYYY-MM-DDTHH:mm");
        appointment_type.value = "time-range";
    }
});
</script>
