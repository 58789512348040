import { ApiErrorResponse, ApiSuccessResponse, ApiPaginatedResponse } from "./api-http-requests";
import { OtpDetails } from "./auth";
import { Currency, TherapySessionLanguage } from "./general";
import { Product, ProductType, ProductTypeAppointment } from "./product";
import { UserDevice } from "./user-device";

/*###########
### ENUMS ###
###########*/
export enum UserLang {
    PL = "pl"
}
export const UserLangLabel = Object.freeze({
    [UserLang.PL]: "Polski"
});
export const UserLangSelectItems = Object.entries(UserLangLabel).map(it => ({
    text: it[1],
    value: it[0]
}));
export enum UserRole {
    USER = "user",
    THERAPIST = "therapist",
    ADMIN = "admin"
}
export enum UserStatus {
    ACTIVE = "active",
    SUSPENDED = "suspended"
}
export enum UserAuthMethod {
    OTP = "otp",
    PASSWORD = "password"
}
export enum UserTwoFactorMethod {
    NONE = "none",
    EMAIL = "email",
    SMS = "sms",
    GOOGLE_AUTHENTICATOR = "ga"
}
export enum UserSignupSource {
    RISIFY_SELF_SIGNUP = "ris_ss",
    RISIFY_MANUALLY_CREATED = "ris_mc",
    VCITA = "vcita"
}
export enum UserGender {
    MALE = "male",
    FEMALE = "female",
    NONBINARY = "nonbinary",
    UNKNOWN = "unknown"
}
export enum UserNotificationChannel {
    GENERAL = "general",
    THERAPY_SESSIONS = "ths",
    CHAT = "chat",
    OTHER = "other"
}
export const UserGenderLabel = Object.freeze({
    [UserGender.FEMALE]: "Kobieta",
    [UserGender.MALE]: "Mężczyzna",
    [UserGender.NONBINARY]: "Osoba niebinarna",
    [UserGender.UNKNOWN]: "Nie chcę podawać"
});
export const UserGenderSelectItems = Object.entries(UserGenderLabel).map(it => ({
    text: it[1],
    value: it[0]
}));
export enum UserClientAgeGroup {
    UNKNOWN = "unknown",
    UNDER_18 = "u18",
    FROM_18_TO_30 = "18_30",
    FROM_31_TO_40 = "31_40",
    FROM_41_TO_50 = "41_50",
    OVER_50 = "o50"
}
export const UserClientAgeGroupLabel = Object.freeze({
    [UserClientAgeGroup.UNKNOWN]: "Brak danych",
    [UserClientAgeGroup.UNDER_18]: "Poniżej 18 lat",
    [UserClientAgeGroup.FROM_18_TO_30]: "18-30 lat",
    [UserClientAgeGroup.FROM_31_TO_40]: "31-40 lat",
    [UserClientAgeGroup.FROM_41_TO_50]: "41-50 lat",
    [UserClientAgeGroup.OVER_50]: "Powyżej 50 lat"
});
export enum UserClientFirstTherapy {
    UNKNOWN = "unknown",
    YES = "yes",
    NO = "no"
}
export const UserClientFirstTherapyLabel = Object.freeze({
    [UserClientFirstTherapy.UNKNOWN]: "Brak danych",
    [UserClientFirstTherapy.YES]: "Tak",
    [UserClientFirstTherapy.NO]: "Nie"
});
export enum UserPreferredTherapyHour {
    IRRELEVANT = "irrelevant",
    H8_TO_H11 = "8:00-11:00",
    H11_TO_H14 = "11:00-14:00",
    H14_TO_H16 = "14:00-16:00",
    H14_TO_H17 = "14:00-17:00",
    H16_TO_H18 = "16:00-18:00",
    H17_TO_H20 = "17:00-20:00",
    H18_TO_H20 = "18:00-20:00",
    H20_TO_H22 = "20:00-22:00"
}
export const UserPreferredTherapyHourLabel: Record<UserPreferredTherapyHour, string> =
    Object.freeze({
        [UserPreferredTherapyHour.IRRELEVANT]: "Bez znaczenia",
        [UserPreferredTherapyHour.H8_TO_H11]: "8:00-11:00",
        [UserPreferredTherapyHour.H11_TO_H14]: "11:00-14:00",
        [UserPreferredTherapyHour.H14_TO_H16]: "14:00-16:00",
        [UserPreferredTherapyHour.H14_TO_H17]: "14:00-17:00",
        [UserPreferredTherapyHour.H16_TO_H18]: "16:00-18:00",
        [UserPreferredTherapyHour.H17_TO_H20]: "17:00-20:00",
        [UserPreferredTherapyHour.H18_TO_H20]: "18:00-20:00",
        [UserPreferredTherapyHour.H20_TO_H22]: "20:00-22:00"
    });
export enum UserActivePreferredTherapyHour {
    IRRELEVANT = UserPreferredTherapyHour.IRRELEVANT,
    H8_TO_H11 = UserPreferredTherapyHour.H8_TO_H11,
    H11_TO_H14 = UserPreferredTherapyHour.H11_TO_H14,
    H14_TO_H16 = UserPreferredTherapyHour.H14_TO_H16,
    H16_TO_H18 = UserPreferredTherapyHour.H16_TO_H18,
    H18_TO_H20 = UserPreferredTherapyHour.H18_TO_H20
}
const UserActivePreferredTherapyHours = [
    UserActivePreferredTherapyHour.IRRELEVANT,
    UserActivePreferredTherapyHour.H8_TO_H11,
    UserActivePreferredTherapyHour.H11_TO_H14,
    UserActivePreferredTherapyHour.H14_TO_H16,
    UserActivePreferredTherapyHour.H16_TO_H18,
    UserActivePreferredTherapyHour.H18_TO_H20
];
export const UserPreferredTherapyHourSelectItems = Object.entries(
    UserPreferredTherapyHourLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));
export const UserActivePreferredTherapyHourSelectItems = UserActivePreferredTherapyHours.map(
    it => ({
        text: UserPreferredTherapyHourLabel[it],
        value: it
    })
);
export enum UserPreferredTherapyStart {
    IRRELEVANT = "irrelevant",
    WITHIN_48H = "48h",
    WITHIN_7D = "7d"
}
export const UserPreferredTherapyStartLabel = Object.freeze({
    [UserPreferredTherapyStart.IRRELEVANT]: "Bez znaczenia",
    [UserPreferredTherapyStart.WITHIN_48H]: "W ciągu 48h",
    [UserPreferredTherapyStart.WITHIN_7D]: "W ciągu 7 dni"
});
export const UserPreferredTherapyStartSelectItems = Object.entries(
    UserPreferredTherapyStartLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));
export enum UserDefaultPaymentMethodType {
    BLIK = "blik",
    PRZELEWY24 = "p24",
    CARD = "card"
}

export enum UserTherapistSpecialistType {
    PSYCHOTHERAPIST = "psychotherapist",
    COACH = "coach"
}

export enum UserTherapistJobTitle {
    PSYCHOLOGIST = "psychologist",
    PSYCHOTHERAPIST = "psychotherapist",
    PEDAGOGUE = "pedagogue",
    ADDICTION_SPECIALIST = "addiction_specialist",
    COACH = "coach"
}
export const UserTherapistJobTitleLabel = Object.freeze({
    [UserTherapistJobTitle.PSYCHOLOGIST]: "psycholog",
    [UserTherapistJobTitle.PSYCHOTHERAPIST]: "psychoterapeuta",
    [UserTherapistJobTitle.PEDAGOGUE]: "pedagog",
    [UserTherapistJobTitle.ADDICTION_SPECIALIST]: "terapeuta uzależnień",
    [UserTherapistJobTitle.COACH]: "coach"
});
export const UserTherapistJobTitleSelectItems = Object.entries(UserTherapistJobTitleLabel).map(
    it => ({
        text: it[1],
        value: it[0]
    })
);

export enum UserTherapistTherapyType {
    ADULTS_INDIVIDUAL = "adults_individual",
    ADULTS_COUPLES = "adults_couples",
    KIDS_AND_TEENS = "kids_and_teens",
    FAMILY = "family",
    EMDR = "emdr"
}
export const UserTherapistTherapyTypeLabel = Object.freeze({
    [UserTherapistTherapyType.ADULTS_INDIVIDUAL]: "Terapia indywidualna",
    [UserTherapistTherapyType.ADULTS_COUPLES]: "Terapia dla par",
    [UserTherapistTherapyType.KIDS_AND_TEENS]: "Dla dzieci i młodzieży",
    [UserTherapistTherapyType.FAMILY]: "Rodzinna",
    [UserTherapistTherapyType.EMDR]: "EMDR"
});
export const UserTherapistTherapyTypeSelectItems = Object.entries(
    UserTherapistTherapyTypeLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));
export type UserPrimaryTherapyType = "unknown" | UserTherapistTherapyType; // quasi enum
export enum UserTherapistTherapyDirection {
    CBT = "cbt",
    PSYCHODYNAMIC = "psychodynamic",
    EMDR = "emdr",
    INTEGRATIVE = "integrative",
    GESTALT = "gestalt",
    HUMANISTIC = "humanistic",
    TSNR = "tsnr",
    SYSTEMIC = "systemic",
    EXISTENTIAL = "existential",
    PSYCHOANALYSIS = "psychoanalysis",
    OTHER = "other"
}
export const UserTherapistTherapyDirectionLabel = Object.freeze({
    [UserTherapistTherapyDirection.CBT]: "CBT",
    [UserTherapistTherapyDirection.EXISTENTIAL]: "Egzystencjalna",
    [UserTherapistTherapyDirection.EMDR]: "EMDR",
    [UserTherapistTherapyDirection.GESTALT]: "Gestalt",
    [UserTherapistTherapyDirection.HUMANISTIC]: "Humanistyczna",
    [UserTherapistTherapyDirection.INTEGRATIVE]: "Integracyjna",
    [UserTherapistTherapyDirection.PSYCHOANALYSIS]: "Psychoanaliza",
    [UserTherapistTherapyDirection.PSYCHODYNAMIC]: "Psychodynamiczna",
    [UserTherapistTherapyDirection.SYSTEMIC]: "Systemowa",
    [UserTherapistTherapyDirection.TSNR]: "TSNR",
    [UserTherapistTherapyDirection.OTHER]: "Inne"
});
export const UserTherapistTherapyDirectionSelectItems = Object.entries(
    UserTherapistTherapyDirectionLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));

export enum UserTherapistSpecialization {
    ADDICTION = "addiction",
    DEPRESSION = "depression",
    ANXIETY = "anxiety",
    PROCRASTINATION = "procrastination",
    STRESS = "stress",
    RELATIONSHIP = "relationship",
    TRAUMA = "trauma",
    ANGER = "anger",
    SADNESS = "sadness",
    SELF_CONFIDENCE = "self_confidence",
    OTHER = "other"
}
export const UserTherapistSpecializationLabel = Object.freeze({
    [UserTherapistSpecialization.DEPRESSION]: "Depresja",
    [UserTherapistSpecialization.ANXIETY]: "Lęk",
    [UserTherapistSpecialization.SELF_CONFIDENCE]: "Pewność siebie",
    [UserTherapistSpecialization.PROCRASTINATION]: "Prokrastynacja",
    [UserTherapistSpecialization.SADNESS]: "Smutek",
    [UserTherapistSpecialization.STRESS]: "Stres",
    [UserTherapistSpecialization.TRAUMA]: "Trauma",
    [UserTherapistSpecialization.ADDICTION]: "Uzależnienia",
    [UserTherapistSpecialization.ANGER]: "Złość",
    [UserTherapistSpecialization.RELATIONSHIP]: "Związki",
    [UserTherapistSpecialization.OTHER]: "Inne"
});
export const UserTherapistSpecializationSelectItems = Object.entries(
    UserTherapistSpecializationLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));

export type UserTherapistSettlementCurrency = Currency.PLN;
export enum UserTherapistSettlementStrategy {
    MONTHLY = "monthly"
}
export enum UserTherapistAvailabilityStrategySchemaWeekday {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}
export enum UserTherapistAssignedProductCommissionStrategy {
    PERCENTAGE = "percentage",
    FIXED_AMOUNT = "fixed_amount"
}
export enum UserTherapistAssignedProductCommissionProgressiveValueStrategy {
    NONE = "percentage",
    RISIFY_PVS_1 = "ris_pvs_1"
}
export const UserTherapistAvailabilityStrategySchemaWeekdayLabel = Object.freeze({
    [UserTherapistAvailabilityStrategySchemaWeekday.MONDAY]: "Poniedziałek",
    [UserTherapistAvailabilityStrategySchemaWeekday.TUESDAY]: "Wtorek",
    [UserTherapistAvailabilityStrategySchemaWeekday.WEDNESDAY]: "Środa",
    [UserTherapistAvailabilityStrategySchemaWeekday.THURSDAY]: "Czwartek",
    [UserTherapistAvailabilityStrategySchemaWeekday.FRIDAY]: "Piątek",
    [UserTherapistAvailabilityStrategySchemaWeekday.SATURDAY]: "Sobota",
    [UserTherapistAvailabilityStrategySchemaWeekday.SUNDAY]: "Niedziela"
});
export const UserTherapistAvailabilityStrategySchemaWeekdaySelectItems = Object.entries(
    UserTherapistAvailabilityStrategySchemaWeekdayLabel
).map(it => ({
    text: it[1],
    value: it[0]
}));

/*##############
### PARTIALS ###
##############*/
export type UserProfileImage = {
    // Pola url_* są adresami URL do konkretnych wielkości obrazów - każde pole istnieje, natomiast nie ma gwarancji, że pola z wyższą rozdzielczością będą zawierały obrazy wyższej rozdzielczości
    url_xs: string;
    url_sm: string;
    url_md: string;
    url_lg: string;
    url_xl: string;
    s3_bucket: string;
    s3_keys: string[];
};
export type UserProductCredit = {
    product: Pick<ProductTypeAppointment, "name" | "type" | "subtype" | "appointment" | "_id">;
    quantity: number;
};
export type UserTherapyInterviewData = {
    client_gender: UserGender;
    client_problems: string[]; // Opcja na dowolny string, ale będziemy raczej preferować umieszczanie tutaj UserTherapistSpecializations
    client_problems_description: string;
    client_age_group: UserClientAgeGroup;
    client_first_therapy: UserClientFirstTherapy;
    therapy_type: UserPrimaryTherapyType;
    therapist_gender: UserGender;
    therapy_language: TherapySessionLanguage;
    therapy_directions: UserTherapistTherapyDirection[];
    therapy_hours: UserPreferredTherapyHour[];
    therapy_start: UserPreferredTherapyStart;
};
export type UserTherapistInfo = {
    specialist_type: UserTherapistSpecialistType;
    job_title: UserTherapistJobTitle[];

    city: string;
    state: string;
    country: string; // kod alpha-2 (wielkimi literami)

    experience_years: number;
    experience_hours: number;
    experience_therapy_minutes: number; // z tego będziemy wyświetlać ilość godzin przeprowadzonych sesji terapeutycznych

    general_rate_avg: number;
    general_rate_count: number;
    ths_rate_avg: number;
    ths_rate_count: number;

    bio: string;
    therapy_types: UserTherapistTherapyType[];
    therapy_directions: UserTherapistTherapyDirection[];
    specializations: UserTherapistSpecialization[];
    languages: TherapySessionLanguage[];
    education: string[];
    courses: string[];
    publications: string[];
    certificates: string[];
};
export type UserTherapistAvailabilityStrategy = {
    _id: string;
    weekdays: UserTherapistAvailabilityStrategySchemaWeekday[];
    from: string;
    start_time: string;
    to: string;
    end_time: string;
    booking_window: number;
    timerise_strategies: Record<string, string>;
    timezone: string;
};
export type UserTherapistSettings = {
    settlement_currency: UserTherapistSettlementCurrency;
    settlement_strategy: UserTherapistSettlementStrategy;
};

type UserTherapistAssignedProductBase = {
    product: Omit<ProductTypeAppointment, "price" | "active" | "c_date" | "m_date">;
    timerise_service_id: string;
    buffer_time_after: number;
    enabled: boolean;
    commission_strategy: UserTherapistAssignedProductCommissionStrategy;
    commission_value: number;
};
type UserTherapistAssignedProductPvsNone = UserTherapistAssignedProductBase & {
    commission_progressive_value_strategy: UserTherapistAssignedProductCommissionProgressiveValueStrategy.NONE;
};
type UserTherapistAssignedProductPvsRis1 = UserTherapistAssignedProductBase & {
    commission_progressive_value_strategy: UserTherapistAssignedProductCommissionProgressiveValueStrategy.RISIFY_PVS_1;
    ris_pvs_1: {
        first_ths_commission_value: number;
        second_ths_commission_value: number;
    };
};
export type UserTherapistAssignedProduct =
    | UserTherapistAssignedProductPvsNone
    | UserTherapistAssignedProductPvsRis1;

export type LinkedUser = {
    _id: string;
    first_name: string;
    last_name: string;
    profile_image?: UserProfileImage;
};

export type UserTherapistRecommendationTherapist = {
    id: string;
    first_name: string;
    last_name: string;
    description: string;
    image: string;
    redirect_url: string;
    profile: UserTherapistInfo;
};

/*##########
### MAIN ###
##########*/
type UserBase = {
    _id: string;

    auth_method: UserAuthMethod;
    two_factor_method: UserTwoFactorMethod;

    email: string;
    first_name: string;
    last_name: string;
    gender: UserGender;
    phone?: string;
    phone_cc?: string;
    profile_image?: UserProfileImage;

    status: UserStatus;

    settings: {
        lang: UserLang;

        timezone_select_automatically: boolean;
        timezone_auto_assigned: string;
        timezone_manually_selected: string;

        email_notifications: boolean;
        sms_notifications: boolean;

        notification_channels: UserNotificationChannel[];

        default_payment_method: UserDefaultPaymentMethodType;
    };

    signup_source: UserSignupSource;

    deleted: boolean;

    c_date: number;
    m_date: number;
};

export type AdminUser = UserBase & {
    role: UserRole.ADMIN;
};
export type UserUser = UserBase & {
    role: UserRole.USER;
    user_segment: {
        _id: string;
        currency: Currency;
    };
    product_credits: Record<string, UserProductCredit>;
    user_thid?: UserTherapyInterviewData;
    user_current_ths_product?: string;
    user_current_therapist?: string;
    linked_users: LinkedUser[];
};
export type TherapistUser = UserBase & {
    role: UserRole.THERAPIST;
    product_credits: Record<string, UserProductCredit>;
    therapist_info: UserTherapistInfo;
    therapist_settings: UserTherapistSettings;
    therapist_availability_strategies: UserTherapistAvailabilityStrategy[];
    therapist_assigned_products: Record<string, UserTherapistAssignedProduct>;
    therapist_ths_contract?: {
        name: string;
        invoice_name: string;
        value: number;
        ths_count: number;
    };
    therapist_active_products: string[];
};
export type User = AdminUser | UserUser | TherapistUser;

export type UserPublicDataFragment = Pick<
    User,
    "_id" | "first_name" | "last_name" | "profile_image"
>;

export type UserPublicDataWithContactInfo = UserPublicDataFragment & {
    email?: string;
    phone_cc?: string;
    phone?: string;
};

export type TherapistUserPublicData = Pick<
    TherapistUser,
    | "_id"
    | "first_name"
    | "last_name"
    | "gender"
    | "profile_image"
    | "role"
    | "therapist_info"
    | "status"
    | "therapist_active_products"
>;

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiUserResponse = ApiSuccessResponse & {
    user: User;
};
export type ApiUserUsersResponse = ApiPaginatedResponse & {
    users: UserPublicDataFragment[];
};
export type ApiUserMeResponse = ApiSuccessResponse & {
    user: User;
    user_permissions: string[];
    user_device: UserDevice;
};
export type ApiUserRecommendedTherapistsResponse = ApiSuccessResponse & {
    therapists: TherapistUserPublicData[];
    product: Pick<
        Extract<Product, { type: ProductType.APPOINTMENT }>,
        "_id" | "type" | "subtype" | "appointment" | "name"
    >;
};
export type ApiUserEmailUpdateResponse =
    | (ApiErrorResponse & {
          otp_details?: OtpDetails;
      })
    | (ApiSuccessResponse & {
          user: User;
      });
export type ApiUserPhoneUpdateResponse = ApiUserEmailUpdateResponse;
export type ApiUserTherapistResponse = ApiSuccessResponse & {
    user: TherapistUserPublicData;
    active_products: Pick<
        ProductTypeAppointment,
        "_id" | "name" | "type" | "subtype" | "appointment"
    >[];
};
export type ApiUserTherapistsResponse = ApiPaginatedResponse & {
    therapists: TherapistUserPublicData[];
};
export type ApiTherapistAvailabilityWindowsResponse = ApiSuccessResponse & {
    aw: Array<Array<number>>;
    ex: Array<Array<number>>;
    arp: Array<Array<number>>;
    tsbase: number;
};
