<template>
    <g transform="matrix(1.4999231,0,0,1.4999231,6.1538462e-4,-2.4195941e-4)">
        <path
            d="M 8,0 C 4.3949712,0 1.5,3.0712496 1.5,6.7753906 c 0,2.1695314 1.5551642,4.2290534 2.9804687,5.9472654 1.4253046,1.718213 2.84375,3.015625 2.84375,3.015625 a 1.0001,1.0001 0 0 0 1.3515626,0 c 0,0 1.4184457,-1.297412 2.8437497,-3.015625 C 12.944836,11.004444 14.5,8.944922 14.5,6.7753906 14.5,3.0712558 11.605053,0 8,0 Z m 0,2 c 2.470141,0 4.5,2.1006518 4.5,4.7753906 0,1.0199055 -1.194839,3.0729976 -2.5195312,4.6699224 C 8.9928607,12.635879 8.4404324,13.122605 8,13.544922 7.5595676,13.122605 7.0071393,12.635879 6.0195312,11.445313 4.6948385,9.8483882 3.5,7.7952961 3.5,6.7753906 3.5,4.1006581 5.5298948,2 8,2 Z"
        />
        <path
            d="M 8,4 C 6.6311368,4 5.5,5.1311368 5.5,6.5 5.5,7.8688632 6.6311368,9 8,9 9.3688632,9 10.5,7.8688632 10.5,6.5 10.5,5.1311368 9.3688632,4 8,4 Z"
        />
    </g>
</template>
