<template>
    <path
        d="M 11 14 C 9.9072954 14 9 14.907295 9 16 L 9 21 C 9 22.092705 9.9072954 23 11 23 L 13 23 C 14.092705 23 15 22.092705 15 21 L 15 16 C 15 14.907295 14.092705 14 13 14 L 11 14 z "
    />
    <path
        d="M 3 18 C 1.9072956 18 1 18.907283 1 20 L 1 21 C 1 22.092729 1.9072958 23 3 23 L 5 23 C 6.0927042 23 7 22.092729 7 21 L 7 20 C 7 18.907271 6.0927042 18 5 18 L 3 18 z "
    />
    <path
        d="m 18,1 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 1.585937 L 13.90625,8.6796875 10.599609,6.1992187 a 1.0001,1.0001 0 0 0 -1.3066402,0.09375 L 1.2929687,14.292969 a 1,1 0 0 0 0,1.414062 1,1 0 0 0 1.4140626,0 L 10.09375,8.3203125 13.400391,10.800781 a 1.0001,1.0001 0 0 0 1.30664,-0.09375 L 21,4.4140625 V 6 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 2 A 1,1 0 0 0 22.923828,1.6171875 1,1 0 0 0 22.707031,1.2929687 1,1 0 0 0 22.382812,1.0761719 1,1 0 0 0 22,1 Z"
    />
    <path
        d="M 19 10 C 17.907295 10 17 10.907295 17 12 L 17 21 C 17 22.092705 17.907295 23 19 23 L 21 23 C 22.092705 23 23 22.092705 23 21 L 23 12 C 23 10.907295 22.092705 10 21 10 L 19 10 z "
    />
</template>
