<template>
    <g>
        <path
            d="M 12,1 A 11,11 0 0 0 1,12 11,11 0 0 0 12,23 11,11 0 0 0 23,12 11,11 0 0 0 12,1 Z m 0,1.8945312 A 9.1063471,9.1063471 0 0 1 21.105469,12 9.1063471,9.1063471 0 0 1 12,21.105469 9.1063471,9.1063471 0 0 1 2.8945312,12 9.1063471,9.1063471 0 0 1 12,2.8945312 Z"
        />
        <path
            d="m 7.8603517,6.7343751 c -0.287275,10e-8 -0.5747968,0.1099532 -0.7949218,0.3300783 -0.4402501,0.4402501 -0.4402506,1.1495932 -2e-7,1.5898435 L 10.411133,12 7.0654299,15.345703 c -0.4402501,0.44025 -0.4402499,1.149594 -2e-7,1.589844 0.4402503,0.44025 1.1495934,0.44025 1.5898435,0 l 3.3457028,-3.345703 3.345704,3.345703 c 0.44025,0.44025 1.14764,0.44025 1.58789,0 0.44025,-0.44025 0.440251,-1.149594 0,-1.589844 L 13.588867,12 16.93457,8.6542967 c 0.44025,-0.44025 0.440251,-1.1495932 0,-1.5898435 -0.44025,-0.4402504 -1.14764,-0.4402499 -1.58789,1e-7 L 12.000977,10.410156 8.6552734,7.0644532 C 8.4351482,6.844328 8.1476266,6.734375 7.8603517,6.7343751 Z"
        />
    </g>
</template>
