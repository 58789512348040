<template>
    <img
        ref="imgRef"
        :src="lazySrc"
        :data-src="src"
        :data-srcset="srcset"
        :alt="alt"
        class="lazy"
    />
</template>

<script setup lang="ts">
import { VNodeRef, onMounted, ref } from "vue";
import LazyLoad from "vanilla-lazyload";
import { useGeneralStore } from "@/stores/general";

defineProps<{
    src: string;
    lazySrc: string;
    alt?: string;
    srcset?: string;
}>();

const generalStore = useGeneralStore();
const imgRef = ref<VNodeRef | null>(null);

onMounted(() => {
    if (generalStore.image_lazyloader_instance === null) {
        generalStore.setImageLazyloaderInstance(new LazyLoad());
    }
    if (imgRef.value) {
        generalStore.image_lazyloader_instance?.update();
    }
});
</script>
