import { Moment } from "moment";

const monthsNominative =
        "styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień".split(
            "_"
        ),
    monthsSubjective =
        "stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia".split(
            "_"
        ),
    monthsParse = [
        /^sty/i,
        /^lut/i,
        /^mar/i,
        /^kwi/i,
        /^maj/i,
        /^cze/i,
        /^lip/i,
        /^sie/i,
        /^wrz/i,
        /^paź/i,
        /^lis/i,
        /^gru/i
    ];

function plural(n: number) {
    return n % 10 < 5 && n % 10 > 1 && ~~(n / 10) % 10 !== 1;
}
function translate(number: number, withoutSuffix: boolean, key: string) {
    var result = number + " ";
    switch (key) {
        case "ss":
            return result + (plural(number) ? "sekundy" : "sekund");
        case "m":
            return withoutSuffix ? "minuta" : "minutę";
        case "mm":
            return result + (plural(number) ? "minuty" : "minut");
        case "h":
            return withoutSuffix ? "godzina" : "godzinę";
        case "hh":
            return result + (plural(number) ? "godziny" : "godzin");
        case "ww":
            return result + (plural(number) ? "tygodnie" : "tygodni");
        case "MM":
            return result + (plural(number) ? "miesiące" : "miesięcy");
        case "yy":
            return result + (plural(number) ? "lata" : "lat");
    }
}

export default {
    months: function (momentToFormat: Moment, format: string) {
        if (!momentToFormat) {
            return monthsNominative;
        } else if (/D MMMM/.test(format)) {
            return monthsSubjective[momentToFormat.month()];
        } else {
            return monthsNominative[momentToFormat.month()];
        }
    },
    monthsShort: "Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru".split("_"),
    monthsParse: monthsParse,
    longMonthsParse: monthsParse,
    shortMonthsParse: monthsParse,
    weekdays: "niedziela_poniedziałek_wtorek_środa_czwartek_piątek_sobota".split("_"),
    weekdaysShort: "ndz_pon_wt_śr_czw_pt_sob".split("_"),
    weekdaysMin: "Nd_Pn_Wt_Śr_Cz_Pt_So".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD.MM.YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd, D MMMM YYYY HH:mm"
    },
    calendar: {
        sameDay: "[Dziś o] LT",
        nextDay: "[Jutro o] LT",
        nextWeek: function () {
            //@ts-expect-error
            switch (this.day()) {
                case 0:
                    return "[W niedzielę o] LT";

                case 2:
                    return "[We wtorek o] LT";

                case 3:
                    return "[W środę o] LT";

                case 6:
                    return "[W sobotę o] LT";

                default:
                    return "[W] dddd [o] LT";
            }
        },
        lastDay: "[Wczoraj o] LT",
        lastWeek: function () {
            // @ts-expect-error
            switch (this.day()) {
                case 0:
                    return "[W zeszłą niedzielę o] LT";
                case 3:
                    return "[W zeszłą środę o] LT";
                case 6:
                    return "[W zeszłą sobotę o] LT";
                default:
                    return "[W zeszły] dddd [o] LT";
            }
        },
        sameElse: "L"
    },
    relativeTime: {
        future: "za %s",
        past: "%s temu",
        s: "kilka sekund",
        ss: translate,
        m: translate,
        mm: translate,
        h: translate,
        hh: translate,
        d: "1 dzień",
        dd: "%d dni",
        w: "tydzień",
        ww: translate,
        M: "miesiąc",
        MM: translate,
        y: "rok",
        yy: translate
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: "%d.",
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
};
