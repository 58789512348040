<template>
    <RisifyInput
        :counter="counter"
        :hint="hint"
        :label="label"
        :id="id"
        :rules="rules"
        :show-asterisk="showAsterisk"
        :value="v"
        :class-name="className"
        :disabled="disabled"
        :dense="dense"
        v-model:error="error_state"
        @click:append-outer="e => emit('click:append-outer', e)"
        @click:prepend-outer="e => emit('click:prepend-outer', e)"
        @keydown="(e : KeyboardEvent) => emit('keydown', e)"
        ref="custom_input_ref"
    >
        <template
            v-if="hasSlot('label')"
            v-slot:label
        >
            <slot name="label"> </slot>
        </template>
        <template
            v-if="hasSlot('hint')"
            v-slot:hint
        >
            <slot name="hint"></slot>
        </template>

        <template
            v-if="hasSlot('prepend-outer')"
            v-slot:prepend-outer
        >
            <slot name="prepend-outer"></slot>
        </template>
        <div
            class="risify-text-field text-link-1"
            :class="{
                'risify-text-field--disabled': disabled,
                'risify-text-field--focused': focused,
                'risify-text-field--invalid': error_state,
                'risify-text-field--outlined': outlined,
                'risify-text-field--dense': dense
            }"
            @click="
                e => {
                    emit('click', e);
                    input_ref?.focus();
                }
            "
            ref="main_ref"
        >
            <div
                class="risify-text-field__prepend"
                v-if="hasSlot('prepend')"
                @click.stop="e => emit('click:prepend', e)"
            >
                <slot name="prepend"></slot>
            </div>

            <div
                class="risify-text-field__prefix"
                v-if="prefix"
            >
                {{ prefix }}
            </div>

            <div class="risify-text-field__tfield">
                <input
                    :type="type"
                    :disabled="disabled"
                    :readonly="readonly"
                    class="risify-text-field__input text-link-1"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    :id="id"
                    :name="name"
                    ref="input_ref"
                    :value="v"
                    @input="onInput"
                    @keydown="e => emit('keydown', e)"
                    :inputmode="inputmode"
                />
                <div
                    class="risify-text-field__placeholder"
                    v-if="is_empty"
                >
                    {{ placeholder }}
                </div>
            </div>
            <div
                class="risify-text-field__suffix"
                v-if="suffix"
            >
                {{ suffix }}
            </div>
            <div
                class="risify-text-field__append"
                v-if="hasSlot('append')"
                @click.stop="e => emit('click:append', e)"
            >
                <slot name="append"></slot>
            </div>
        </div>
        <template
            v-if="hasSlot('append-outer')"
            v-slot:append-outer
        >
            <slot name="append-outer"></slot>
        </template>
    </RisifyInput>
</template>

<script setup lang="ts">
import { ref, computed, useSlots, nextTick, watch, onMounted } from "vue";
import RisifyInput, { RisifyInputProps } from "@/components/form-inputs/RisifyInput.vue";

export type RisifyTextFieldProps = Omit<RisifyInputProps<string>, "value"> & {
    outlined?: boolean;
    readonly?: boolean;
    name?: string;
    prefix?: string;
    suffix?: string;
    type?: string;
    placeholder?: string;
    modelValue: string;
    inputmode?: "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url";
    autofocus?: boolean;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifyTextFieldProps>(), {
    type: "text",
    disabled: false,
    inputmode: "text",
    autofocus: false
});

const emit = defineEmits<{
    (e: "update:modelValue", v: string): void;
    (e: "keydown", event: KeyboardEvent): void;
    (e: "click", event: MouseEvent | PointerEvent): void;
    (e: "click:append-outer", event: MouseEvent | PointerEvent): void;
    (e: "click:prepend-outer", event: MouseEvent | PointerEvent): void;
    (e: "click:append", event: MouseEvent | PointerEvent): void;
    (e: "click:prepend", event: MouseEvent | PointerEvent): void;
    (e: "focus", event: FocusEvent): void;
    (e: "blur", event: FocusEvent): void;
}>();

defineOptions({
    inheritAttrs: false
});

defineExpose({
    validate,
    resetValidation
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const initial_v_set = ref<boolean>(false);
const v = ref<string>("");
const focused = ref<boolean>(false);
const error_state = ref<boolean>(false);

// element refs
const custom_input_ref = ref<any>();
const input_ref = ref<HTMLElement>();
const main_ref = ref<HTMLElement>();

/*##############
### COMPUTED ###
##############*/
const is_empty = computed(() => {
    return v.value === "";
});

/*##############
### WATCHERS ###
##############*/
watch(() => props.modelValue, handleValue, { immediate: true });
watch(() => props.error, handleErrorPropChange, { immediate: true });
watch(() => props.autofocus, handleAutofocus);

/*#############
### METHODS ###
#############*/
function validate() {
    if (!custom_input_ref.value) return false;
    return custom_input_ref.value.validate();
}
function resetValidation() {
    if (!custom_input_ref.value) return;
    return custom_input_ref.value.resetValidation();
}

function onInput(ev: any) {
    v.value = (ev.target as HTMLInputElement).value;
    emit("update:modelValue", v.value);
    nextTick(validate);
}

function handleValue() {
    if (props.modelValue != undefined) {
        v.value = props.modelValue;

        if (initial_v_set.value) {
            nextTick(validate);
        }

        initial_v_set.value = true;
    }
}

function handleFocus(ev: any) {
    focused.value = true;
    emit("focus", ev);
}
function handleBlur(ev: any) {
    focused.value = false;
    emit("blur", ev);
}

function hasSlot(name: string) {
    return !!slots[name];
}

function handleErrorPropChange(nv: boolean | undefined) {
    if (nv === false || nv === true) {
        error_state.value = nv;
    }
}

function handleAutofocus() {
    if (props.autofocus === true && input_ref.value) {
        input_ref.value.focus();
    }
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onMounted(() => {
    handleAutofocus();
});
</script>
