import isNetworkError from "is-network-error";
import { displayAlert } from "../main";

import router from "./router";
import { cacheRequest, getCachedRequest } from "./browserCache";

import { useGeneralStore } from "../stores/general";
import { useAuthStore } from "../stores/auth";

import { waitForMs } from "../helpers/waiters";

import {
    HttpRequestOpts,
    ApiErrorResponse,
    ApiSuccessResponse,
    ApiResponse
} from "../types/api-http-requests";

import ErrorMessages from "../stores/json/response-messages.json";
import { PushParameter } from "notivue";
import { emitter } from "./eventEmitter";

interface HttpErrorMessageDictionary {
    [path: string]: HttpErrorMessageDictionary | string;
}

class HttpRequestJsonErrorResponse<T = ApiErrorResponse> {
    public status: number;
    public response: T;

    constructor(http_status: number, response_body: T) {
        this.status = http_status;
        this.response = response_body;
    }
}
export function isHttpJsonRequestErrorResponse(err: unknown): err is HttpRequestJsonErrorResponse {
    return err instanceof HttpRequestJsonErrorResponse;
}

const PUBLIC_API_ENDPOINTS: string[] = [
    "/auth/register",
    "/auth/login",
    "/auth/refresh-token",
    "/auth/revoke-token",
    "/test",
    "/quizzes/:url_name",
    "/quizzes/grabg/:group_name",
    "/quiz-boards",
    "/quiz-sessions",
    "/quiz-sessions/:id",
    "/user-invites/:id"
];

function getNestedObjProperty(obj: HttpErrorMessageDictionary, property: string) {
    let wo = obj;
    const SPL_PROP = property.split(".");

    for (let i = 0; i < SPL_PROP.length; i++) {
        if (!wo.hasOwnProperty(SPL_PROP[i])) {
            return null;
        }
        const PROP = wo[SPL_PROP[i]];
        if (typeof PROP == "string") {
            return PROP;
        }
        wo = PROP;
    }

    return null;
}
function messageExists(path: string) {
    return getNestedObjProperty(ErrorMessages, path) !== null;
}
function getMessage(path: string) {
    const t = getNestedObjProperty(ErrorMessages, path);
    return t === null ? path : t;
}

function requestRequiresAuth(url: string) {
    const U = new URL(url);
    let pathname = U.pathname;
    if (pathname.endsWith("/")) {
        pathname = pathname.slice(0, -1);
    }
    if (pathname.startsWith("/")) {
        pathname = pathname.slice(1);
    }

    const SPL_PN = pathname.split("/");

    for (let i = 0; i < PUBLIC_API_ENDPOINTS.length; i++) {
        let endpoint = PUBLIC_API_ENDPOINTS[i];
        const API_PREFIX = import.meta.env.VITE_API_BASE_PATH;
        if (API_PREFIX && !endpoint.startsWith(API_PREFIX)) {
            endpoint = API_PREFIX + endpoint;
        }

        if (endpoint.endsWith("/")) {
            endpoint = endpoint.slice(0, -1);
        }
        if (endpoint.startsWith("/")) {
            endpoint = endpoint.slice(1);
        }

        const SPL_ENDPOINT = endpoint.split("/");
        const IS_WILDCARD = endpoint.indexOf("*") !== -1;

        // Test 1.: zgodna długość
        if (!IS_WILDCARD && SPL_ENDPOINT.length != SPL_PN.length) {
            continue;
        }

        // Test 2.: sprawdzenie po kolei segmentów
        let matched = true;
        for (let j = 0; j < SPL_ENDPOINT.length; j++) {
            // 2.1. Jeżeli segment jest statyczny (nie zaczyna się od : i nie jest *), to musi się literalnie zgadzać
            if (
                SPL_ENDPOINT[j] !== "*" &&
                !SPL_ENDPOINT[j].startsWith(":") &&
                (!SPL_PN[j] || SPL_PN[j] !== SPL_ENDPOINT[j])
            ) {
                matched = false;
                break;
            }
            // 2.2. Jeżeli segment jest dynamiczny, to musi istnieć w URL requestu
            if (SPL_ENDPOINT[j].startsWith(":") && !SPL_PN[j]) {
                matched = false;
                break;
            }
            // 2.3. Jeżeli segment jest gwiazdką to whatever, zawsze będzie pasowało
        }

        if (!matched) continue;

        return false;
    }

    return true;
}

type HttpRequestResponse = {
    response: Response;
    from_cache: boolean;
};
export function httpRequest(
    url: string,
    request_config: RequestInit | undefined = undefined,
    opts: HttpRequestOpts = {}
): Promise<HttpRequestResponse> {
    return new Promise(async (resolve, reject) => {
        const generalStore = useGeneralStore();
        const authStore = useAuthStore();

        const RC = request_config
            ? { ...request_config }
            : {
                  method: "GET"
              };

        // 1. Automatycznie dodajemy base_url jeżeli nie podano
        if (url.startsWith("/") && opts.prepend_api_path !== false) {
            url = generalStore.API_BASE_PATH + url;
        }

        // 2. Jeżeli request idzie na nasze API, to od razu tryb CORS + wtedy zawsze jeżeli mamy, to dajemy token w Authorization headerze
        let request_to_our_api = false;
        if (url.startsWith(generalStore.API_BASE_URL)) {
            RC.mode = "cors";
            request_to_our_api = true;
        }

        // 3. Tworzymy obiekt żądania
        const REQ = new Request(url, RC);

        // 4. Request interceptors
        if (opts.request_interceptors) {
            for (let i = 0; i < opts.request_interceptors.length; i++) {
                try {
                    await opts.request_interceptors[i](REQ, opts);
                } catch (err) {
                    return reject(err);
                }
            }
        }

        // 5. Jeżeli nie wskazano inaczej, to ustawiamy typ na JSON
        if (opts.set_content_type !== false) {
            REQ.headers.set("Content-Type", "application/json");
        }

        // 6. Sprawdzamy, czy żądanie wymaga autoryzacji
        // - jeżeli żądanie wymaga autoryzacji, to oczekujemy na auth_data
        // console.log(url, requestRequiresAuth(url));
        const REQUEST_REQUIRES_ACCESS_TOKEN =
            requestRequiresAuth(url) && opts.bypass_auth_check !== true;

        if (REQUEST_REQUIRES_ACCESS_TOKEN && authStore.auth_data === undefined) {
            while (!generalStore.app_booted) {
                // console.log("Waiting for AppBoot", url);
                await waitForMs(500);
            }
        }

        // 7. Sprawdzamy, czy posiadane AuthData nadal się do czegoś nadaje
        if (REQUEST_REQUIRES_ACCESS_TOKEN) {
            // 7.1. Jeżeli nie mamy danych lub mamy takie, które do niczego się nie nadają to throw
            if (
                !authStore.auth_data ||
                (authStore.auth_data.access_token_exp_date - 60 * 1000 < Date.now() &&
                    authStore.auth_data.refresh_token_exp_date - 60 * 1000 < Date.now())
            ) {
                await authStore.logUserOut({
                    alert_msg:
                        "Twoja sesja wygasła. Zaloguj się ponownie, aby kontynuować korzystanie z aplikacji",
                    alert_type: "error",
                    redirect_route_name: "auth-signin"
                });
                return reject("cannot_preathorize_request");
            }

            // 7.2. Jeżeli dane wymagają odświeżenia, to to robimy
            if (authStore.auth_data.access_token_exp_date - 60 * 1000 < Date.now()) {
                await authStore.refreshAuthData(authStore.auth_data);
            }
        }

        // 8. Jeżeli mamy AuthData, żądanie wymaga autoryzacji, a nie podano innego nagłówka, to ustawiamy go automatycznie
        if (!REQ.headers.has("Authorization") && authStore.auth_data && request_to_our_api) {
            REQ.headers.append("Authorization", `Bearer ${authStore.auth_data.access_token}`);
        }

        // 9. Wysyłamy żądanie
        try {
            const fres = await fetch(REQ);

            // 9.1. Response callbacks
            if (opts.response_callbacks) {
                for (let i = 0; i < opts.response_callbacks.length; i++) {
                    try {
                        await opts.response_callbacks[i](fres, opts);
                    } catch (err) {
                        return reject(err);
                    }
                }
            }

            // 9.2. Opracowujemy zwrot - kod 200 oznacza, że przepuszczamy odpowiedź dalej
            if (fres.ok) {
                // Jeżeli jesteśmy w środowisku natywnym, gdzie nie ma sw.js, to zajmujemy się cache zasobów
                if (REQ.method == "GET") {
                    const cloned_response = await fres.clone();
                    await cacheRequest("api", REQ, cloned_response);
                }

                flushEnqueuedRequests();

                return resolve({
                    response: fres,
                    from_cache: false
                });
            }
            // 9.3. Obsługa błędów (mamy response ale nie z zakresu HTTP 200)
            else {
                // 9.3.1. Retry logic
                if (opts.retry_on_invalid_response) {
                    const SR =
                        typeof opts.retry_on_invalid_response.retry_condition === "boolean"
                            ? opts.retry_on_invalid_response.retry_condition
                            : opts.retry_on_invalid_response.retry_condition(fres);

                    if (SR) {
                        if (opts.retry_on_invalid_response.retry_delay) {
                            if (typeof opts.retry_on_invalid_response.retry_delay === "number") {
                                await waitForMs(opts.retry_on_invalid_response.retry_delay);
                            } else {
                                await waitForMs(opts.retry_on_invalid_response.retry_delay());
                            }
                        }
                        delete opts.retry_on_invalid_response;
                        return resolve(
                            await httpRequest(url, request_config, {
                                ...opts,
                                __is_retry: true
                            })
                        );
                    }
                }

                // 9.3.2. Error handle
                let emsg: string = "";
                if (fres.status === 500) {
                    emsg = "Nieoczekiwany błąd serwera";
                } else if (fres.status === 401) {
                    if (opts.autologout_on_unathorized !== false) {
                        await authStore.logUserOut({
                            alert_msg:
                                "Twoja sesja wygasła. Zaloguj się ponownie, aby kontynuować korzystanie z aplikacji",
                            alert_type: "error",
                            redirect_route_name: "auth-signin"
                        });
                    }
                    emsg = "";
                } else if (fres.status === 403) {
                    emsg = "Nie posiadasz uprawnień do tego zasobu";
                } else if (fres.status === 404) {
                    emsg = "Szukany zasób nie został odnaleziony";
                } else if (fres.status === 429) {
                    emsg = "Przekroczono limit żądań w danym okresie czasu";
                }

                if (opts.supress_errors !== true && emsg != "") {
                    displayAlert.error(emsg);
                }

                return resolve({
                    response: fres,
                    from_cache: false
                });
            }
        } catch (err) {
            // 10. Obsługujemy błędy
            const ISNE = isNetworkError(err);
            if (ISNE) {
                hold_enqueued_requests = true;
            }

            if (ISNE && ["POST", "PUT", "DELETE"].includes(REQ.method)) {
                // 10.1. Jesteśmy offline
                emitter.emit("DefaultLayout::showOfflineDialog");
            } else if (REQ.method == "GET") {
                // 10.2. Próba z fallback do cache
                const CACHED_RESPONSE = await getCachedRequest("api", REQ);
                if (CACHED_RESPONSE != undefined) {
                    return resolve({
                        response: CACHED_RESPONSE,
                        from_cache: true
                    });
                }

                if (opts.offline_behavior === "wait_for_online_and_retry") {
                    try {
                        const R = await enqueueRequestToRetry(url, request_config, {
                            ...opts,
                            offline_behavior: "redirect_to_offline_page"
                        });
                        return resolve(R);
                    } catch (err) {
                        return reject(err);
                    }
                } else {
                    // Jeżeli nie ma nic w cache to go offline
                    if (router.currentRoute.value && router.currentRoute.value.name != "offline") {
                        router.push({
                            name: "offline",
                            query: {
                                rback: router.currentRoute.value.path
                            }
                        });
                    }
                }
            }

            return reject(err);
        }
    });
}

type HttpJsonRequestResponseMeta = {
    __meta: {
        from_cache: boolean;
    };
};
export function httpJsonRequest<
    SR_T extends ApiResponse = ApiSuccessResponse,
    ER_T extends ApiErrorResponse = ApiErrorResponse
>(
    url: string,
    request_config: RequestInit | undefined = undefined,
    opts: HttpRequestOpts = {}
): Promise<SR_T & HttpJsonRequestResponseMeta> {
    return new Promise(async (resolve, reject) => {
        try {
            const HTTPRR = await httpRequest(url, request_config, opts);

            const RESPONSE_BODY: SR_T = await HTTPRR.response.json();

            if (HTTPRR.response.ok === true) {
                return resolve({
                    ...RESPONSE_BODY,
                    __meta: {
                        from_cache: HTTPRR.from_cache
                    }
                });
            } else {
                const ERR_RES: ER_T = JSON.parse(JSON.stringify(RESPONSE_BODY));
                let emsg: string | PushParameter = "";

                if (ERR_RES.success === false) {
                    emsg = ERR_RES.msg ? ERR_RES.msg : "Nieznany błąd żądania";

                    if (ERR_RES.error_code) {
                        const REQ_URL = new URL(HTTPRR.response.url);
                        const KEY_PATH =
                            REQ_URL.pathname
                                .slice(1)
                                .split("/")
                                .filter(it => /^[0-9a-fA-F]{24}$/.test(it) !== true)
                                .filter(it => /^[0-9]{6}$/.test(it) !== true)
                                .join(".") +
                            "." +
                            ERR_RES.error_code.toString();
                        // console.log(KEY_PATH);
                        if (messageExists(KEY_PATH)) {
                            const EM = getMessage(KEY_PATH).split("|");
                            if (EM.length === 1) {
                                emsg = EM[0];
                            } else if (EM.length === 2) {
                                emsg = {
                                    title: EM[0],
                                    message: EM[1]
                                };
                            } else if (EM.length === 3) {
                                emsg = {
                                    title: EM[0],
                                    message: EM[1],
                                    duration: !isNaN(parseInt(EM[2])) ? parseInt(EM[2]) : 5000
                                };
                            } else if (EM.length === 4) {
                                emsg = {
                                    title: EM[0],
                                    message: EM[1],
                                    duration: !isNaN(parseInt(EM[2])) ? parseInt(EM[2]) : 5000,
                                    props: {
                                        size: EM[3] === "wide" ? "wide" : "normal"
                                    }
                                };
                            }
                        }
                    }
                } else {
                    emsg = "Otrzymano pole { success: true } w żądaniu zgłaszającym błąd";
                }

                if (opts.supress_errors !== true && emsg != "") {
                    displayAlert.error(emsg);
                }

                return reject(
                    new HttpRequestJsonErrorResponse<ER_T>(HTTPRR.response.status, ERR_RES)
                );
            }
        } catch (err) {
            return reject(err);
        }
    });
}

let hold_enqueued_requests: boolean = true;
export function flushEnqueuedRequests() {
    hold_enqueued_requests = false;
}
export function holdEnqueuedRequests() {
    hold_enqueued_requests = false;
}
function awaitFlushEnqueueRequests(): Promise<void> {
    return new Promise(resolve => {
        function c() {
            if (hold_enqueued_requests === false) return resolve();
            else setTimeout(c, 1000);
        }
        c();
    });
}
async function enqueueRequestToRetry(
    url: string,
    request_config: RequestInit | undefined = undefined,
    opts: HttpRequestOpts = {}
): Promise<HttpRequestResponse> {
    await awaitFlushEnqueueRequests();
    return httpRequest(url, request_config, opts);
}
