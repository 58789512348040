import { httpJsonRequest } from "@/plugins/fetchApi";
import { ApiAppointmentResponse, Appointment } from "@/types/appointment";
import {
    ApiAppointmentRescheduleProposalResponse,
    AppointmentRescheduleProposal
} from "@/types/appointment-reschedule-proposal";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useFetchedElementsStore = defineStore("fetched-elements", () => {
    /*##################
    ### APPOINTMENTS ###
    ##################*/
    const appointments = ref<Appointment[]>([]);
    const appointments_fetch_map = new Map<string, Promise<Appointment>>();
    const invalidated_appointments = new Set<string>();

    function updateOrInsertAppointment(appointment: Appointment) {
        const ix = appointments.value.findIndex(it => it._id === appointment._id);
        if (ix === -1) {
            appointments.value.push(appointment);
        } else {
            appointments.value.splice(ix, 1, {
                ...appointments.value[ix],
                ...appointment
            });
        }
    }
    function invalidateAppointment(id: string) {
        invalidated_appointments.add(id);
    }

    function retrieveAppointment(id: string) {
        return appointments.value.find(it => it._id === id);
    }

    async function getAppointment(id: string) {
        // 1. Jeżeli dany element jest invalidated + mamy go pobranego, to go usuwamy
        if (invalidated_appointments.has(id)) {
            const IX = appointments.value.findIndex(it => it._id === id);
            if (IX !== -1) {
                appointments.value.splice(IX, 1);
            }
            invalidated_appointments.delete(id);
        }

        // 2. Sprawdzamy, czy mamy już taki element
        const APPOINTMENT = appointments.value.find(it => it._id === id);
        if (APPOINTMENT) return APPOINTMENT;

        // 3. Sprawdzamy, czy czasem już go nie pobieramy
        const PROMISE = appointments_fetch_map.get(id);
        if (PROMISE) {
            return await PROMISE;
        }

        // 4. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
        appointments_fetch_map.set(
            id,
            new Promise(async (resolve, reject) => {
                try {
                    const R = await httpJsonRequest<ApiAppointmentResponse>(
                        `/appointments/${id}`,
                        {},
                        { supress_errors: true }
                    );
                    appointments.value.push(R.appointment);
                    return resolve(R.appointment);
                } catch (err) {
                    return reject(err);
                }
            })
        );
        const O = await appointments_fetch_map.get(id);
        if (O === undefined) {
            throw new Error("Could not fetch Appointment!");
        }

        appointments_fetch_map.delete(id);
        return O;
    }

    /*######################################
    ### APPOINTMENT RESCHEDULE PROPOSALS ###
    ######################################*/
    const arp = ref<AppointmentRescheduleProposal[]>([]);
    const arp_fetch_map = new Map<string, Promise<AppointmentRescheduleProposal>>();

    function updateOrInsertAppointmentRescheduleProposal(item: AppointmentRescheduleProposal) {
        const ix = arp.value.findIndex(it => it._id === item._id);
        if (ix === -1) {
            arp.value.push(item);
        } else {
            arp.value.splice(ix, 1, {
                ...arp.value[ix],
                ...item
            });
        }
    }

    function retrieveAppointmentRescheduleProposal(id: string) {
        return arp.value.find(it => it._id === id);
    }

    async function getAppointmentRescheduleProposal(id: string) {
        // 1. Sprawdzamy, czy mamy już taki element
        const ARP = arp.value.find(it => it._id === id);
        if (ARP) return ARP;

        // 2. Sprawdzamy, czy czasem już go nie pobieramy
        const PROMISE = arp_fetch_map.get(id);
        if (PROMISE) {
            return await PROMISE;
        }

        // 3. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
        arp_fetch_map.set(
            id,
            new Promise(async (resolve, reject) => {
                try {
                    const R = await httpJsonRequest<ApiAppointmentRescheduleProposalResponse>(
                        `/appointment-reschedule-proposals/${id}`,
                        {},
                        { supress_errors: true }
                    );
                    arp.value.push(R.appointment_reschedule_proposal);
                    return resolve(R.appointment_reschedule_proposal);
                } catch (err) {
                    return reject(err);
                }
            })
        );
        const O = await arp_fetch_map.get(id);
        if (O === undefined) {
            throw new Error("Could not fetch Appointment!");
        }

        arp_fetch_map.delete(id);
        return O;
    }

    /*##########
    ### NUKE ###
    ##########*/
    function nuke() {
        appointments.value = [];
        appointments_fetch_map.clear();

        arp.value = [];
        arp_fetch_map.clear();
    }

    return {
        updateOrInsertAppointment,
        invalidateAppointment,
        retrieveAppointment,
        getAppointment,

        updateOrInsertAppointmentRescheduleProposal,
        retrieveAppointmentRescheduleProposal,
        getAppointmentRescheduleProposal,

        nuke
    };
});
