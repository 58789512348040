import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { emitter } from "@/plugins/eventEmitter";

let window_is_focused = true;
if (!Capacitor.isNativePlatform()) {
    window.addEventListener("focus", () => {
        window_is_focused = true;
        emitBrowserFocusEvent();
    });
    window.addEventListener("blur", () => {
        window_is_focused = false;
        emitBrowserFocusEvent();
    });
}

let tab_is_visible = true;
if (!Capacitor.isNativePlatform()) {
    document.addEventListener("visibilitychange", () => {
        tab_is_visible = document.visibilityState === "visible";
        emitBrowserFocusEvent();
    });
}

let ebfe_debouncer: NodeJS.Timeout | null = null;
function emitBrowserFocusEvent() {
    if (ebfe_debouncer !== null) clearTimeout(ebfe_debouncer);
    ebfe_debouncer = setTimeout(() => {
        emitter.emit("Browser::activestatechange", window_is_focused && tab_is_visible);
        ebfe_debouncer = null;
    }, 10);
}

export async function appIsFocused() {
    if (Capacitor.isNativePlatform()) {
        return (await App.getState()).isActive;
    } else {
        return document.hasFocus();
    }
}

export function waitForDocumentReady(): Promise<void> {
    return new Promise(resolve => {
        function c() {
            if (document.readyState === "complete") {
                return resolve();
            }
            setTimeout(c, 500);
        }
        c();
    });
}

export function localStorageAvailable() {
    return window && window.localStorage !== undefined;
}
