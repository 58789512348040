<template>
    <div
        class="spinner"
        :class="{
            'spinner--absolute': absolute
        }"
        :style="{
            'background-color': computed_background_color
        }"
    >
        <div
            class="spinner__inner"
            :style="{
                width: computed_size + 'px',
                height: computed_size + 'px'
            }"
        >
            <svg
                class="spinner__spinner"
                viewBox="0 0 50 50"
            >
                <circle
                    class="path"
                    cx="25"
                    cy="25"
                    r="21"
                    fill="none"
                    :stroke="computed_color"
                    :stroke-width="computed_width"
                ></circle>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isValidCssColor } from "@/helpers/validators";

/*###############
### VARIABLES ###
############## */
const DEFAULT_COLORS = [
    "red",
    "orange",
    "green",
    "light-green",
    "dark-green",
    "yellow",
    "black",
    "white",
    "gray"
];

const COLOR_MAP = {
    primary: "light-green",
    secondary: "orange",
    success: "green",
    error: "red"
};

const props = withDefaults(
    defineProps<{
        color?: string;
        background?: string;
        size?: number | string;
        width?: number | string;
        absolute?: boolean;
    }>(),
    {
        color: "var(--color-gray)",
        background: "inherit",
        size: 48,
        width: 4,
        absolute: false
    }
);

/*##############
### COMPUTED ###
############# */

const computed_color = computed(() => {
    const mapped_color = mapColor(props.color);
    if (isPresetColor(mapped_color)) {
        return `var(--color-${mapped_color})`;
    } else if (isValidCssColor(mapped_color)) {
        return mapped_color;
    }
    return "var(--color-gray)";
});

const computed_background_color = computed(() => {
    const mapped_color = mapColor(props.background);
    if (isPresetColor(mapped_color)) {
        return `var(--color-${mapped_color})`;
    } else if (isValidCssColor(mapped_color)) {
        return mapped_color;
    }
    return "inherit";
});

const computed_size = computed(() => {
    if (typeof props.size === "number") return props.size;
    return parseInt(props.size);
});
const computed_width = computed(() => {
    if (typeof props.width === "number") return props.width;
    return parseInt(props.width);
});

/*#############
### METHODS ###
############ */
function mapColor(color: string) {
    return color in COLOR_MAP ? COLOR_MAP[color as keyof typeof COLOR_MAP] : color;
}

function isPresetColor(c: string) {
    return DEFAULT_COLORS.indexOf(c) !== -1;
}
</script>
