<template>
    <div
        class="risify-select-element"
        :class="{
            'risify-select-element--disabled': disabled
        }"
        :tabindex="disabled ? -1 : 0"
        @focus="focus()"
        @keypress.enter.prevent.space.prevent="toggleMenu()"
        ref="root_el_ref"
        v-click-outside="onClickOutside"
    >
        <div
            class="risify-select-element__inner"
            @click.stop="
                () => {
                    toggleMenu();
                }
            "
        >
            <div class="risify-select-element__selection text-link-1">
                <div
                    class="risify-select-element__placeholder"
                    v-if="nothing_selected"
                >
                    {{ placeholder }}
                </div>
                <template v-else>
                    <slot
                        name="selection"
                        v-if="hasSlot('selection')"
                        :items="selected_items_populated"
                    ></slot>
                    <div
                        v-else
                        class="risify-select-element__defaultselection"
                    >
                        {{ selection_text }}
                    </div>
                </template>
            </div>
            <div class="risify-select-element__ticon">
                <CustomIcon
                    size="16"
                    name="chevron-down"
                    class="risify-select-element__chevron"
                    :class="{
                        'risify-select-element__chevron--inverted': menu_visible
                    }"
                    color="currentColor"
                />
            </div>
        </div>

        <Teleport to="body">
            <div
                class="risify-select-element-dropdown"
                :class="{
                    'risify-select-element-dropdown--invalid': error,
                    'risify-select-element-dropdown--outlined': outlined
                }"
                :style="{
                    width: computed_dropdown_width,
                    zIndex: menu_zindex
                }"
                ref="menu_ref"
                :data-hidden="!menu_visible || menuType !== 'dropdown'"
                @click.stop
                @keydown.tab="
                    {
                        closeMenu();
                        blur();
                    }
                "
                @keydown.esc="
                    () => {
                        if (menuType === 'dropdown') {
                            closeMenu();
                            root_el_ref?.focus();
                        }
                    }
                "
                @keydown.up="
                    e => {
                        if (menuType === 'dropdown') {
                            menuKeyboardNavigate(e);
                        }
                    }
                "
                @keydown.down="
                    e => {
                        if (menuType === 'dropdown') {
                            menuKeyboardNavigate(e);
                        }
                    }
                "
            >
                <slot
                    name="prepend-item"
                    v-if="hasSlot('prepend-item')"
                ></slot>

                <div
                    v-if="menuType === 'dropdown'"
                    class="risify-select-element-dropdown__wrapper"
                >
                    <div class="risify-select-element-dropdown__options text-link-2">
                        <div
                            class="risify-select-element-dropdown__item"
                            :class="{
                                'risify-select-element-dropdown__item--selected': isSelected(
                                    item.value
                                ),
                                'risify-select-element-dropdown__item--disabled':
                                    item.disabled === true
                            }"
                            v-for="item in items"
                            :key="item.value.toString()"
                            :data-value="item.value"
                            @click="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            @keydown.enter.prevent="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            @keydown.space.prevent="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            :tabindex="menu_visible ? '0' : '-1'"
                        >
                            <div
                                v-if="multiple"
                                class="risify-select-element-dropdown__itcontrol risify-select-element-dropdown__itcontrol--checkbox"
                            >
                                <RisifyCheckboxElement
                                    :model-value="selected_items"
                                    @update:model-value="onItemClick(item.value)"
                                    :true-value="item.value"
                                    multiple
                                />
                            </div>
                            <div
                                class="risify-select-element-dropdown__itcontent"
                                :class="{
                                    'risify-select-element-dropdown__itcontent--multiple': multiple
                                }"
                            >
                                <slot
                                    name="item"
                                    :item="item"
                                    :is-selected="isSelected(item.value)"
                                    v-if="hasSlot('item')"
                                ></slot>
                                <div
                                    v-else
                                    class="risify-select-element-dropdown__itemtext"
                                >
                                    {{ item.text }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="risify-select-element-dropdown__nodata text-link-2"
                            v-if="items.length == 0"
                        >
                            {{ emptyListMsg }}
                        </div>
                    </div>
                </div>

                <slot
                    name="append-item"
                    v-if="hasSlot('append-item')"
                ></slot>
            </div>
        </Teleport>

        <Dialog
            v-if="menuType === 'dialog'"
            v-model="menu_visible"
            :class="dialog_classes"
            @cancel="root_el_ref?.focus()"
            @keydown.up.prevent="
                e => {
                    if (menuType === 'dialog') {
                        menuKeyboardNavigate(e);
                    }
                }
            "
            @keydown.down.prevent="
                e => {
                    if (menuType === 'dialog') {
                        menuKeyboardNavigate(e);
                    }
                }
            "
        >
            <template #header>
                <DialogBaseHeader
                    :close-button="!multiple"
                    @close="closeMenu"
                    v-if="dialogTitle"
                >
                    {{ dialogTitle }}
                </DialogBaseHeader>
            </template>

            <template #default>
                <div
                    class="risify-select-element-dialog__wrapper"
                    ref="menu_ref"
                >
                    <div class="risify-select-element-dialog__options text-link-2">
                        <div
                            class="risify-select-element-dialog__item"
                            :class="{
                                'risify-select-element-dialog__item--selected': isSelected(
                                    item.value
                                ),
                                'risify-select-element-dialog__item--disabled':
                                    item.disabled === true
                            }"
                            v-for="item in items"
                            :key="item.value.toString()"
                            :data-value="item.value"
                            @click="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            @keydown.enter.prevent="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            @keydown.space.prevent="
                                _ => {
                                    if (item.disabled !== true) onItemClick(item.value);
                                }
                            "
                            :tabindex="menu_visible ? '0' : '-1'"
                        >
                            <div
                                v-if="multiple"
                                class="risify-select-element-dialog__itcontrol risify-select-element-dialog__itcontrol--checkbox"
                            >
                                <RisifyCheckboxElement
                                    :model-value="selected_items"
                                    @update:model-value="onItemClick(item.value)"
                                    :true-value="item.value"
                                    multiple
                                />
                            </div>
                            <div class="risify-select-element-dialog__itcontent">
                                <slot
                                    name="item"
                                    :item="item"
                                    :is-selected="isSelected(item.value)"
                                    v-if="hasSlot('item')"
                                ></slot>
                                <div
                                    v-else
                                    class="risify-select-element-dialog__itemtext"
                                >
                                    {{ item.text }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="risify-select-element-dialog__nodata text-link-2"
                            v-if="items.length == 0"
                        >
                            {{ emptyListMsg }}
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <DialogBaseFooter v-if="multiple">
                    <RisifyButton
                        @click="closeMenu(true)"
                        small
                        style="margin-left: auto"
                    >
                        {{ dialogSubmitBtnText }}
                    </RisifyButton>
                </DialogBaseFooter>
            </template>
        </Dialog>
    </div>
</template>

<script setup lang="ts" generic="T">
import { ref, computed, useSlots, watch, onMounted, onUnmounted, onUpdated, nextTick } from "vue";
import { createPopper, type Instance, type Placement } from "@popperjs/core";

import { pluralize } from "@/helpers/pluralizer";
import { getMaxZIndex } from "@/helpers/layout";

import RisifyButton from "@/components/buttons/RisifyButton.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import RisifyCheckboxElement from "@/components/form-inputs/RisifyCheckboxElement.vue";

export type RisifySelectValue = string | number | boolean;
export type RisifySelectOption<T> = T & {
    text: string;
    value: RisifySelectValue;
    disabled?: boolean;
};
export type RisifySelectElementProps<T> = {
    items?: RisifySelectOption<T>[];
    modelValue: RisifySelectValue | RisifySelectValue[];
    placeholder?: string;
    multiple?: boolean;
    emptyListMsg?: string;
    disabled?: boolean;
    popperDistance?: string | number;
    popperSkidding?: string | number;
    dropdownWidth?: string | number;
    dropdownAlignment?: Placement;
    dropdowFallbackPlacement?: Placement[];
    disableClickOutside?: boolean;
    error?: boolean;
    menuType?: "dropdown" | "dialog";
    dialogTitle?: string;
    dialogSubmitBtnText?: string;
    outlined?: boolean;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifySelectElementProps<T>>(), {
    multiple: false,
    emptyListMsg: "Brak elementów do wyświetlenia",
    disabled: false,
    popperDistance: 16,
    popperSkidding: 0,
    dropdownWidth: "auto",
    dropdownAlignment: "auto",
    disableClickOutside: false,
    items: () => [],
    dialogTitle: "",
    dialogSubmitBtnText: "Gotowe",
    menuType: "dropdown"
});

const emit = defineEmits<{
    (e: "update:modelValue", v: RisifySelectValue | RisifySelectValue[]): void;
    (e: "focus", v: true): void;
    (e: "blur", v: true): void;
    (e: "menu-state-change", v: boolean): void;
}>();

defineOptions({
    inheritAttrs: false
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const root_el_ref = ref<HTMLElement>();
const root_el_width = ref(0);

const menu_ref = ref<HTMLElement>();

const menu_visible = ref(false);
const menu_zindex = ref(1);

const selected_items = ref<RisifySelectValue[]>([]);
const cached_items = ref<RisifySelectOption<T>[]>([]);

const popper_ref = ref<Instance>();

const dialog_wrapper_overflows_on_top = ref<boolean>(false);
const dialog_wrapper_overflows_on_bottom = ref<boolean>(false);

/*##############
### COMPUTED ###
##############*/
const nothing_selected = computed(() => {
    return (
        selected_items.value.length === 0 ||
        selected_items.value.filter(it => it !== "").length === 0
    );
});

const selection_text = computed(() => {
    const OUTPUT = [];
    for (let i = 0; i < selected_items.value.length; i++) {
        if (selected_items.value[i] === "") continue; // skip empty value

        const CI = cached_items.value.find(it => it.value == selected_items.value[i]);
        if (CI) {
            OUTPUT.push(CI.text);
        } else {
            OUTPUT.push("N/A");
        }
    }

    if (OUTPUT.length > 3) {
        return (
            OUTPUT.slice(0, 2).join(", ") +
            ", +" +
            pluralize(OUTPUT.length - 2, ["", "1 inna", "{n} inne", "{n} innych"])
        );
    }

    return OUTPUT.join(", ");
});
const selected_items_populated = computed(() => {
    const A = [];
    for (let i = 0; i < selected_items.value.length; i++) {
        const CI = cached_items.value.find(it => it.value == selected_items.value[i]);
        if (CI) {
            A.push(CI);
        }
    }
    return A;
});

const computed_dropdown_width = computed(() => {
    if (props.dropdownWidth === "auto") {
        return root_el_width.value + "px";
    }
    if (typeof props.dropdownWidth === "number") return props.dropdownWidth.toString() + "px";
    return props.dropdownWidth;
});

const dialog_classes = computed(() => {
    const A = ["risify-select-element-dialog"];

    if (dialog_wrapper_overflows_on_top.value) {
        A.push("risify-select-element-dialog--overflowing-on-top");
    }
    if (dialog_wrapper_overflows_on_bottom.value) {
        A.push("risify-select-element-dialog--overflowing-on-bottom");
    }

    return A.join(" ");
});

/*##############
### WATCHERS ###
##############*/
watch(
    () => props.modelValue,
    () => {
        handleValue();
        cacheSelectedItems();
    },
    {
        immediate: true
    }
);

watch(menu_visible, () => {
    emit("menu-state-change", menu_visible.value);
});

/*#############
### METHODS ###
#############*/
// 1. Utils
function doCalcs() {
    if (root_el_ref.value) {
        root_el_width.value = root_el_ref.value.offsetWidth;
    }
}
function hasSlot(name: string) {
    return !!slots[name];
}
function handleValue() {
    if (props.modelValue != undefined) {
        if (Array.isArray(props.modelValue)) {
            selected_items.value = props.modelValue;
        } else {
            selected_items.value = [props.modelValue];
        }
    }
}
function cacheSelectedItems() {
    const A = [];
    for (let i = 0; i < selected_items.value.length; i++) {
        const ITEM = props.items.find(it => it.value == selected_items.value[i]);
        if (ITEM) {
            A.push(JSON.parse(JSON.stringify(ITEM)));
        }
    }
    cached_items.value = A;
}
function menuKeyboardNavigate(e: KeyboardEvent) {
    if (!menu_visible.value || !menu_ref.value) {
        return;
    }

    const ITEM_ELS = menu_ref.value.querySelectorAll<HTMLElement>(
        props.menuType === "dialog"
            ? ".risify-select-element-dialog__item"
            : ".risify-select-element-dropdown__item"
    );

    let current_focused_index = 0;
    for (let i = 0; i < ITEM_ELS.length; i++) {
        if (document.activeElement === ITEM_ELS[i]) current_focused_index = i;
    }

    let new_focused_index = current_focused_index;

    if (e.key === "ArrowUp") {
        new_focused_index = ITEM_ELS.length - 1;
        for (let i = current_focused_index - 1; i >= 0; i--) {
            if (
                !ITEM_ELS[i].classList.contains(
                    `risify-select-element-${
                        props.menuType === "dialog" ? "dialog" : "dropdown"
                    }__item--disabled`
                )
            ) {
                new_focused_index = i;
                break;
            }
        }
    } else if (e.key === "ArrowDown") {
        new_focused_index = 0;
        for (let i = current_focused_index + 1; i < ITEM_ELS.length; i++) {
            if (
                !ITEM_ELS[i].classList.contains(
                    `risify-select-element-${
                        props.menuType === "dialog" ? "dialog" : "dropdown"
                    }__item--disabled`
                )
            ) {
                new_focused_index = i;
                break;
            }
        }
    }
    ITEM_ELS[new_focused_index].focus();
}

// 2. Menu
function onClickOutside(e: any) {
    if (props.disableClickOutside) return;

    if (
        !menu_ref.value ||
        menu_visible.value === false ||
        menu_ref.value.contains(e.target) ||
        e.target.classList.contains("risify-select-element-dropdown")
    ) {
        return;
    }

    closeMenu();
    blur();
}
function toggleMenu() {
    if (menu_visible.value === false) {
        openMenu();
    } else {
        closeMenu();
    }
}
function openMenu() {
    if (props.disabled || menu_visible.value === true) {
        return;
    }

    menu_visible.value = true;

    if (props.menuType === "dialog") {
        nextTick(() => {
            handleDialogOverflows();
            if (menu_ref.value) {
                menu_ref.value.addEventListener("scroll", handleDialogOverflows, {
                    passive: true
                });
            }
        });
    } else {
        mountPopper();
        menu_zindex.value = getMaxZIndex() + 1;
    }

    // focus pierwszego zaznaczonego elementu
    if (selected_items.value.length > 0 && menu_ref.value) {
        const ITEM_ELS = menu_ref.value?.querySelectorAll<HTMLElement>(
            props.menuType === "dialog"
                ? ".risify-select-element-dialog__item"
                : ".risify-select-element-dropdown__item"
        );
        for (let i = 0; i < ITEM_ELS.length; i++) {
            const IV = ITEM_ELS[i].getAttribute("data-value");
            if (IV && selected_items.value.includes(IV)) {
                nextTick(() => {
                    ITEM_ELS[i].focus();
                });
                break;
            }
        }
    }

    // focus ogólny
    focus();
}
function closeMenu(refocus_root_el = true) {
    if (menu_visible.value !== true) {
        return;
    }
    if (props.menuType === "dialog") {
        if (menu_ref.value) {
            menu_ref.value.removeEventListener("scroll", handleDialogOverflows);
        }
    } else {
        destroyPopper();
    }
    menu_visible.value = false;

    if (refocus_root_el) {
        root_el_ref.value?.focus();
    }
}
function mountPopper() {
    if (popper_ref.value != undefined) return;
    nextTick(() => {
        if (root_el_ref.value == null || !menu_ref.value) return;
        popper_ref.value = createPopper(root_el_ref.value, menu_ref.value, {
            strategy: "absolute",
            placement: props.dropdownAlignment,
            modifiers: [
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: props.dropdowFallbackPlacement
                    }
                },
                {
                    name: "offset",
                    options: {
                        offset: [props.popperSkidding, props.popperDistance]
                    }
                }
            ]
        });
    });
}
function destroyPopper() {
    if (popper_ref.value == undefined) return;
    popper_ref.value.destroy();
    popper_ref.value = undefined;
}
function handleDialogOverflows() {
    if (!menu_ref.value) return;

    // top
    if (menu_ref.value.scrollHeight > menu_ref.value.clientHeight && menu_ref.value.scrollTop > 0) {
        dialog_wrapper_overflows_on_top.value = true;
    } else {
        dialog_wrapper_overflows_on_top.value = false;
    }

    // bottom
    if (
        menu_ref.value.scrollHeight > menu_ref.value.clientHeight &&
        menu_ref.value.scrollTop + menu_ref.value.clientHeight < menu_ref.value.scrollHeight
    ) {
        dialog_wrapper_overflows_on_bottom.value = true;
    } else {
        dialog_wrapper_overflows_on_bottom.value = false;
    }
}

// 3. Element state
function focus() {
    emit("focus", true);
}
function blur() {
    emit("blur", true);
}

// 4. Item
function onItemClick(v: RisifySelectValue) {
    let fv = JSON.parse(JSON.stringify(selected_items.value));

    if (props.multiple === true) {
        if (isSelected(v)) {
            fv.splice(fv.indexOf(v), 1);
        } else {
            fv.push(v);

            const FV_IXMAP = [];
            for (let i = 0; i < fv.length; i++) {
                const ix = props.items.findIndex(it => it.value == fv[i]);
                FV_IXMAP.push({
                    v: fv[i],
                    ix
                });
            }
            FV_IXMAP.sort((a, b) => a.ix - b.ix);
            fv = FV_IXMAP.map(it => it.v);
        }

        emit("update:modelValue", fv);
    } else {
        if (fv[0] !== v) {
            fv = v;
            emit("update:modelValue", fv);
        }
    }

    if (!props.multiple) {
        closeMenu();
    }
}
function isSelected(v: RisifySelectValue) {
    return selected_items.value.indexOf(v) !== -1;
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onMounted(() => {
    window.addEventListener("resize", doCalcs, { passive: true });
});

onUnmounted(() => {
    window.removeEventListener("resize", doCalcs);
});

onUpdated(() => {
    doCalcs();
});

/*#############
### EXPOSES ###
#############*/
defineExpose({
    focus,
    blur,
    openMenu,
    closeMenu,
    toggleMenu,
    menu_visible
});
</script>
