<template>
    <div
        class="sq-mc mt-4 text-label-1"
        ref="question_title_ref"
    >
        {{ question.title }}<span class="sq-mc__asterisk">{{ is_required ? "*" : "" }}</span>
    </div>
    <div
        v-if="
            question.multiple_choice.min_choices >= 0 && question.multiple_choice.max_choices === -1
        "
        class="sq-mc__subtitle text-link-3"
    >
        Wybierz przynajmniej {{ question.multiple_choice.min_choices }}
        {{ question.multiple_choice.min_choices === 1 ? "opcję" : "opcje" }}
    </div>
    <div
        v-else-if="
            question.multiple_choice.min_choices === 0 && question.multiple_choice.max_choices > 0
        "
        class="sq-mc__subtitle text-link-3"
    >
        Wybierz maksymalnie
        {{ question.multiple_choice.max_choices }}
        {{ question.multiple_choice.max_choices === 1 ? "opcję" : "opcje" }}
    </div>
    <div
        v-else-if="
            question.multiple_choice.min_choices >= 0 && question.multiple_choice.max_choices > 0
        "
        class="sq-mc__subtitle text-link-3"
    >
        Wybierz od {{ question.multiple_choice.min_choices }} do
        {{
            question.multiple_choice.max_choices === -1
                ? "dowolnej ilości"
                : question.multiple_choice.max_choices
        }}
        opcji
    </div>
    <div
        v-if="question.multiple_choice.layout === SurveyQuestionMultipleChoiceLayout.LIST_1COL"
        class="sq-mc__layout sq-mc__layout--1col mt-4"
    >
        <div
            v-for="choice in question.choices"
            class="mb-2"
        >
            <RisifyCheckbox
                :key="choice.id"
                :label="choice.label"
                v-model="selected_choices"
                @update:model-value="validate()"
                :true-value="choice.id"
                outlined
                multiple
                variant="tile"
            >
            </RisifyCheckbox>
        </div>
    </div>
    <div
        v-else-if="question.multiple_choice.layout === SurveyQuestionMultipleChoiceLayout.LIST_2COL"
        class="sq-mc__layout sq-mc__layout--2col mt-4"
    >
        <div
            v-for="choice in question.choices"
            class="mb-2"
        >
            <RisifyCheckbox
                :key="choice.id"
                :label="choice.label"
                v-model="selected_choices"
                @update:model-value="validate()"
                :true-value="choice.id"
                outlined
                multiple
                variant="tile"
            >
            </RisifyCheckbox>
        </div>
    </div>
    <div
        v-else-if="question.multiple_choice.layout === SurveyQuestionMultipleChoiceLayout.CLOUD"
        class="sq-mc__layout sq-mc__layout--cloud mt-4"
    >
        <div
            v-for="choice in question.choices"
            class="pa-1"
        >
            <RisifyCheckbox
                :key="choice.id"
                :label="choice.label"
                v-model="selected_choices"
                @update:model-value="validate()"
                :true-value="choice.id"
                outlined
                multiple
                variant="micro-tile"
            >
            </RisifyCheckbox>
        </div>
    </div>
    <p class="sq-mc__errmsg">{{ question_error }}</p>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import RisifyCheckbox from "@/components/form-inputs/RisifyCheckbox.vue";
import {
    SurveyQuestionMultipleChoice,
    SurveyQuestionExpose,
    SurveyQuestionValue,
    SurveyQuestionMultipleChoiceLayout
} from "@/types/surveys";

const props = defineProps<{
    question: SurveyQuestionMultipleChoice;
}>();

defineExpose<SurveyQuestionExpose>({
    getValue,
    validate,
    getAnchorElement: () => question_title_ref.value
});

const selected_choices = ref<string[]>([]);
const question_error = ref<string>("");
const question_title_ref = ref<HTMLElement>();
const is_required = computed(() => {
    return props.question.multiple_choice.min_choices > 0;
});

function getValue(): SurveyQuestionValue | null {
    return selected_choices.value.length >= 1
        ? { question: props.question._id, values: [...selected_choices.value] }
        : null;
}

function validate(): boolean {
    if (selected_choices.value.length < props.question.multiple_choice.min_choices) {
        question_error.value = `Wybierz przynajmniej ${
            props.question.multiple_choice.min_choices
        } ${props.question.multiple_choice.min_choices === 1 ? "odpowiedź" : "odpowiedzi"}`;
        return false;
    } else if (
        selected_choices.value.length > props.question.multiple_choice.max_choices &&
        props.question.multiple_choice.max_choices != 0
    ) {
        question_error.value = `Wybierz maksymalnie ${props.question.multiple_choice.max_choices} ${
            props.question.multiple_choice.max_choices === 1 ? "odpowiedź" : "odpowiedzi"
        }`;
        return false;
    } else {
        question_error.value = "";
    }
    return true;
}
</script>
