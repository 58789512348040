<template>
    <g>
        <path
            d="M 12,1 A 11,11 0 0 0 1,12 11,11 0 0 0 12,23 11,11 0 0 0 23,12 11,11 0 0 0 12,1 Z m 0,1.8945312 A 9.1063471,9.1063471 0 0 1 21.105469,12 9.1063471,9.1063471 0 0 1 12,21.105469 9.1063471,9.1063471 0 0 1 2.8945312,12 9.1063471,9.1063471 0 0 1 12,2.8945312 Z"
        />
        <path
            d="m 11.999999,5.0559564 c 0.641025,0 1.157084,0.5160594 1.157084,1.1570838 v 7.4640418 c 0,0.641025 -0.516059,1.157084 -1.157084,1.157084 -0.641024,0 -1.157083,-0.516059 -1.157083,-1.157084 V 6.2130402 c 0,-0.6410244 0.516059,-1.1570838 1.157083,-1.1570838 z"
        />
        <path
            d="M 13.157085,17.786959 A 1.1570854,1.1570854 0 0 1 12,18.944044 1.1570854,1.1570854 0 0 1 10.842915,17.786959 1.1570854,1.1570854 0 0 1 12,16.629873 a 1.1570854,1.1570854 0 0 1 1.157085,1.157086 z"
        />
    </g>
</template>
