import { nanoid } from "nanoid";
import { computed, ref } from "vue";

const opened_dialogs = ref<string[]>([]);

export function useDialogs() {
    const any_dialog_opened = computed(() => opened_dialogs.value.length > 0);
    const opened_dialog_count = computed(() => opened_dialogs.value.length);

    function registerOpenedDialog(): string {
        const REG_ID = nanoid();
        opened_dialogs.value.push(REG_ID);
        return REG_ID;
    }
    function unregisterOpenedDialog(regid: string) {
        const ix = opened_dialogs.value.indexOf(regid);
        if (ix !== -1) {
            opened_dialogs.value.splice(ix, 1);
        }
    }

    return { any_dialog_opened, opened_dialog_count, registerOpenedDialog, unregisterOpenedDialog };
}
