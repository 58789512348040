export function waitForMs(ms: number = 0) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export class Stopwatch {
    start_time: number;

    constructor() {
        this.start_time = Date.now();
    }

    waitUntil(milliseconds: number): Promise<void> {
        return new Promise(async resolve => {
            const end_time = Date.now();

            if (end_time - this.start_time < milliseconds) {
                await waitForMs(milliseconds - (end_time - this.start_time));
            }

            return resolve();
        });
    }
}
