<template>
    <div class="risify-tooltip">
        <div
            class="risify-tooltip__activator"
            ref="tooltip_activator_el_ref"
            @mouseover="showTooltip"
            @mouseleave="hideTooltip"
        >
            <slot name="activator">
                <div class="risify-tooltip__d-activator">
                    <CustomIcon name="info-circle-outline" />
                </div>
            </slot>
        </div>
        <div
            class="risify-tooltip__content text-link-2 text-weight-medium"
            :class="{
                'risify-tooltip__content--visible': tooltip_active,
                'risify-tooltip__content--light': light
            }"
            :style="{
                maxWidth
            }"
            ref="tooltip_content_el_ref"
        >
            <slot name="content">
                <div class="risify-tooltip__d-content">
                    Place tooltip content here (using #content slot)
                </div>
            </slot>
            <div
                class="risify-tooltip__notch"
                data-popper-arrow
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { createPopper, type Instance, type Placement } from "@popperjs/core";

import CustomIcon from "@/components/generics/CustomIcon.vue";

interface RisifyTooltipProps {
    position?: Placement;
    light?: boolean;
    maxWidth?: string;
}

const props = withDefaults(defineProps<RisifyTooltipProps>(), {
    position: "top",
    light: false,
    maxWidth: "100%"
});

/*###############
### VARIABLES ###
############## */
const tooltip_popper = ref<Instance>();
const tooltip_active = ref<boolean>(false);

const tooltip_activator_el_ref = ref<HTMLElement>();
const tooltip_content_el_ref = ref<HTMLElement>();

/*##############
### COMPUTED ###
############# */

/*#############
### METHODS ###
############ */
const showTooltip = () => {
    tooltip_active.value = true;

    if (tooltip_popper.value) {
        tooltip_popper.value.update();
    }
};

const hideTooltip = () => {
    tooltip_active.value = false;
};

onMounted(() => {
    if (tooltip_activator_el_ref.value && tooltip_content_el_ref.value) {
        tooltip_popper.value = createPopper(
            tooltip_activator_el_ref.value.children.length > 0
                ? tooltip_activator_el_ref.value.children[0]
                : tooltip_activator_el_ref.value,
            tooltip_content_el_ref.value,
            {
                placement: props.position || "top",
                strategy: "fixed",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            }
        );
    }
});
</script>
