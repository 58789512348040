<template>
    <path
        d="m 20.5,12 c 0,-0.822514 -0.677508,-1.5 -1.5,-1.5 -0.822492,0 -1.5,0.677486 -1.5,1.5 0,0.822514 0.677508,1.5 1.5,1.5 0.822492,0 1.5,-0.677486 1.5,-1.5 z"
    />
    <path
        d="m 13.5,12 c 0,-0.822502 -0.677498,-1.5 -1.5,-1.5 -0.822502,0 -1.5,0.677498 -1.5,1.5 0,0.822502 0.677498,1.5 1.5,1.5 0.822502,0 1.5,-0.677498 1.5,-1.5 z"
    />
    <path
        d="m 6.5,12 c 0,-0.822502 -0.677498,-1.5 -1.5,-1.5 -0.822502,0 -1.5,0.677498 -1.5,1.5 0,0.822502 0.677498,1.5 1.5,1.5 0.822502,0 1.5,-0.677498 1.5,-1.5 z"
    />
</template>
