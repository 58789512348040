import { Store, defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { httpJsonRequest } from "@/plugins/fetchApi";
import { ref } from "vue";
import { Chat, ApiChatsResponse, ChatType } from "@/types/chat";
import {
    ApiChatMessagesResponse,
    ApiChatMessageFileUrlResponse,
    ChatMessage
} from "@/types/chat-message";
import { shouldReportChatMessageToMessageLog, isChatMessageRead } from "@/helpers/chats";

export type ChatPagination = {
    cursor: string | null;
    total: number;
    loading: boolean;
    loaded: boolean;
    mark_as_delivered: boolean;
};

export const useChatsStore = defineStore("chats", () => {
    /*###########
    ### SETUP ###
    ###########*/
    const auth_store = useAuthStore();

    /*###########
    ### CHATS ###
    ###########*/
    // w chats mogą się znaleźć różne typy chatów (ChatThusappr | ChatAppvc)
    const last_chat_id = ref<string>("");
    const chats = ref<Chat[]>([]);

    const active_chats: Set<string> = new Set();

    // paginacja dotyczy tylko chatów typu ChatThusappr
    const chats_pagination_cursor = ref<string | null>(null);
    const chats_total = ref<number>(0);

    const chats_loading = ref<boolean>(false);
    const chats_loaded = ref<boolean>(false);
    const all_chats_loaded = ref<boolean>(false);
    const no_chats_found = ref<boolean>(false);

    const is_last_page = ref<boolean>(false);

    function isChatActive(chat_id: string) {
        return active_chats.has(chat_id);
    }

    function setChatActiveState(chat_id: string, state: boolean) {
        if (state === true) {
            active_chats.add(chat_id);
        } else {
            active_chats.delete(chat_id);
        }
    }

    function getChats(cat?: string) {
        if (cat) {
            return chats.value.filter(chat => chat.type === cat);
        }

        return chats.value;
    }

    function getChat(id: string) {
        if (chats.value === undefined) return;

        return chats.value.find(c => c._id === id);
    }

    function findChat(owner: string, host: string, product: string) {
        if (chats.value === undefined) return;

        return chats.value.find(c => {
            if (c.type === ChatType.THUS_APPOINTMENT_PRODUCT) {
                return c.owner === owner && c.host === host && c.product._id === product;
            }
            return false;
        });
    }

    function insertChat(ch: Chat | Chat[]) {
        if (!Array.isArray(ch)) ch = [ch];
        let should_sort = false;

        // jeśli chatu nie ma w tablicy to dodajemy go
        for (const chat of ch) {
            if (chats.value.findIndex(c => c._id === chat._id) === -1) {
                if (
                    auth_store.user &&
                    !isChatMessageRead(chat.lr_message, auth_store.user._id, chat.last_message)
                ) {
                    incrementerUnreadChats(1);
                }
                chats.value.push(chat);
                should_sort = true;
            }
        }

        if (should_sort) {
            sortChats();
        }
    }

    function updateChat(this: Store, ch: Chat) {
        if (chats.value === undefined || auth_store.user === undefined) return;

        const index = chats.value.findIndex(c => c._id === ch._id);

        if (index === -1) return;

        const chat_before = chats.value[index];
        const was_read_before = isChatMessageRead(
            chat_before.lr_message,
            auth_store.user._id,
            chat_before.last_message
        );

        if (ch.users.find(user => auth_store.user && user._id === auth_store.user._id)) {
            chats.value.splice(index, 1, ch);

            const is_read_now = isChatMessageRead(
                ch.lr_message,
                auth_store.user._id,
                ch.last_message
            );

            // 1. Jeśli chat BYŁ odczytany, a TERAZ posiada nieprzeczytane wiadomości to zwiększamy licznik nieprzeczytanych chatów
            if (was_read_before && !is_read_now) {
                incrementerUnreadChats(1);
                // 2. Jeśli chat NIE BYŁ odczytany, a TERAZ jest odczytany to zmniejszamy licznik nieprzeczytanych chatów
            } else if (!was_read_before && is_read_now) {
                incrementerUnreadChats(-1);
            }
        } else {
            chats.value.splice(index, 1);
            chat_messages.value = chat_messages.value.filter(msg => msg.chat !== ch._id);
            delete chat_messages_pagination.value[ch._id];

            if (this.router.currentRoute.value.name === "chat") {
                this.router.replace({
                    name: "home"
                });
            }
        }

        sortChats();
    }

    function sortChats() {
        if (chats.value === undefined) return;

        chats.value = chats.value.sort((a, b) => b.la_date - a.la_date);

        last_chat_id.value = chats.value.length > 0 ? chats.value[0]._id : "";
    }

    async function fetchChats() {
        if (chats_loading.value) return;
        chats_loading.value = true;
        try {
            const res = await httpJsonRequest<ApiChatsResponse<Chat[]>>(
                `/chats/?after=${chats_pagination_cursor.value}&filter_type=thusappr&limit=64`
            );

            insertChat(res.chats);
            chats_pagination_cursor.value = res.pagination.after;
            chats_total.value = res.pagination.total;
            chats_loaded.value = true;
            no_chats_found.value = res.chats.length === 0;

            if (res.pagination.after === null) {
                all_chats_loaded.value = true;
            }
        } catch (e) {
            console.log(e);
        }
        sortChats();
        chats_loading.value = false;
    }

    /*#####################
    ### UNREAD MESSAGES ###
    #####################*/
    const unread_chats_count = ref<number>(0);

    function getUnreadChats() {
        return unread_chats_count.value;
    }

    function setUnreadChats(value: number) {
        unread_chats_count.value = value;
    }

    function incrementerUnreadChats(v: number) {
        unread_chats_count.value += v;

        if (unread_chats_count.value < 0) {
            unread_chats_count.value = 0;
        }
    }

    /*###################
    ### CHAT MESSAGES ###
    ###################*/
    const chat_messages = ref<ChatMessage[]>([]);
    const chat_messages_pagination = ref<Record<string, ChatPagination>>({});

    function getChatMessages(chat_id: string) {
        return chat_messages.value.filter(msg => msg.chat === chat_id);
    }

    function insertChatMessage(msg: ChatMessage | ChatMessage[]) {
        if (!Array.isArray(msg)) msg = [msg];
        let should_sort = false;

        // jeśli wiadomości nie ma w tablicy to dodajemy ją
        for (const m of msg) {
            if (chat_messages.value.findIndex(cm => cm._id === m._id) === -1) {
                chat_messages.value.push(m);
                should_sort = true;
            }
        }

        if (should_sort) {
            sortChatMessages();
        }
    }

    function sortChatMessages() {
        chat_messages.value = chat_messages.value.sort((a, b) => b.c_date - a.c_date);
    }

    async function fetchChatMessages(chat_id: string) {
        // 1. Sprawdza stan paginacji + utworzenie jeżeli brak
        let pagination_object: ChatPagination = {
            cursor: null,
            total: 0,
            loaded: false,
            loading: false,
            mark_as_delivered: false
        };

        if (chat_messages_pagination.value[chat_id] !== undefined) {
            pagination_object = chat_messages_pagination.value[chat_id];
        }

        // 2. Jeżeli aktualnie już pobieramy lub nie ma nic do pobrania to przerywamy
        if (
            pagination_object.loading === true ||
            (pagination_object.loaded === true && pagination_object.cursor === null)
        ) {
            return;
        }

        // 3. Aktualizujemy paginację o flagę loading - rozpoczynamy pobieranie
        pagination_object.loading = true;
        updatePaginationObject(chat_id, pagination_object);

        // 4. Call na serwer
        try {
            const R = await httpJsonRequest<ApiChatMessagesResponse>(
                `/chat-messages/?limit=24&sort_by=c_date&sort_direction=desc&filter_chat=${chat_id}&after=${pagination_object.cursor}`
            );

            if (pagination_object.mark_as_delivered === false) {
                const chat = getChat(chat_id);

                if (chat !== undefined && auth_store.user) {
                    for (const msg of R.chat_messages) {
                        const report = shouldReportChatMessageToMessageLog(
                            msg,
                            chat.ld_message,
                            auth_store.user._id
                        );

                        if (report === "report") {
                            await httpJsonRequest(
                                `/chats/${chat_id}/message-log`,
                                {
                                    method: "POST",
                                    body: JSON.stringify({
                                        chat_message: msg._id,
                                        state: "delivered"
                                    })
                                },
                                {
                                    supress_errors: true
                                }
                            );
                        }

                        if (report === "report" || report === "already_reported") {
                            pagination_object.mark_as_delivered = true;
                            updatePaginationObject(chat_id, pagination_object);
                            break;
                        }
                    }

                    if (isChatActive(chat_id)) {
                        for (const msg of R.chat_messages) {
                            const report = shouldReportChatMessageToMessageLog(
                                msg,
                                chat.lr_message,
                                auth_store.user._id
                            );

                            if (report === "report") {
                                await httpJsonRequest(
                                    `/chats/${chat_id}/message-log`,
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            chat_message: msg._id,
                                            state: "read"
                                        })
                                    },
                                    {
                                        supress_errors: true
                                    }
                                );
                            }

                            if (report === "report" || report === "already_reported") {
                                break;
                            }
                        }
                    }
                }
            }

            // 4.1. Insert wiadomości
            insertChatMessage(R.chat_messages);

            // 4.2. Aktualizacja obiektu paginacji
            pagination_object.loaded = true;
            pagination_object.cursor = R.pagination.after;
            pagination_object.total = R.pagination.total;
            updatePaginationObject(chat_id, pagination_object);

            if (R.pagination.after === null) {
                is_last_page.value = true;
            }
        } catch (err) {
            console.error(err);
        }

        // 5. Zakończenie zadania
        pagination_object.loading = false;
        updatePaginationObject(chat_id, pagination_object);
    }

    function updatePaginationObject(key: string, value: ChatPagination) {
        chat_messages_pagination.value = {
            ...chat_messages_pagination.value,
            [key]: JSON.parse(JSON.stringify(value))
        };
    }

    function deleteChatMessage(msg_id: string) {
        const chat_message = JSON.parse(
            JSON.stringify(chat_messages.value.find(msg => msg._id === msg_id))
        );

        if (chat_message && chat_message.deleted === false) {
            delete chat_message.text;
            delete chat_message.event;
            delete chat_message.html;
            delete chat_message.file;

            chat_message.deleted = true;
        }

        replaceChatMessage(msg_id, chat_message);
    }

    function replaceChatMessage(m_id: string, msg: ChatMessage) {
        const index = chat_messages.value.findIndex(m => m._id === m_id);
        if (index === -1) return;

        chat_messages.value.splice(index, 1, msg);
    }

    async function getChatMessageFileUrl(msg_id: string) {
        const R = await httpJsonRequest<ApiChatMessageFileUrlResponse>(
            `/chat-messages/${msg_id}/file-url`
        );
        return R.url;
    }

    /*#############
    ### GLOBALS ###
    ##############*/
    function nuke() {
        chats.value = [];
        chats_pagination_cursor.value = null;
        chats_total.value = 0;
        chats_loading.value = false;
        chats_loaded.value = false;
        all_chats_loaded.value = false;
        no_chats_found.value = false;
        unread_chats_count.value = 0;
        active_chats.clear();

        chat_messages.value = [];
        chat_messages_pagination.value = {};
    }

    return {
        chats,
        last_chat_id,

        chats_pagination_cursor,
        chats_total,

        chats_loading,
        chats_loaded,
        all_chats_loaded,
        no_chats_found,

        chat_messages,

        chat_messages_pagination,
        is_last_page,

        isChatActive,
        setChatActiveState,

        getUnreadChats,
        setUnreadChats,
        incrementerUnreadChats,

        getChatMessages,
        getChatMessageFileUrl,

        insertChatMessage,
        deleteChatMessage,
        replaceChatMessage,

        sortChatMessages,
        fetchChatMessages,

        getChats,
        getChat,

        fetchChats,

        findChat,
        insertChat,
        updateChat,

        sortChats,

        nuke
    };
});
