<template>
    <Dialog
        v-if="!Capacitor.isNativePlatform()"
        :model-value="visible"
        @update:model-value="e => emit('update:visible', e)"
        @cancel="closeDatePicker"
        class="universal-date-picker__dialog"
        width="263"
    >
        <div :class="{ 'universal-date-picker': true }">
            <VueDatePicker
                v-if="visible"
                v-model="internal_date"
                inline
                :time-picker="mode === 'time'"
                :enable-time-picker="mode !== 'date'"
                @update:model-value="handleSelect"
                select-text="Wybierz"
                :format="preview_format"
                is24
                locale="pl"
                :min-date="proper_min_date"
                :max-date="proper_max_date"
                :min-time="proper_min_time"
                :max-time="proper_max_time"
                ignore-time-validation
                :minutes-increment="5"
                :minutes-grid-increment="5"
            >
                <template #arrow-left>
                    <CustomIcon name="chevron-left" />
                </template>
                <template #arrow-right>
                    <CustomIcon name="chevron-right" />
                </template>
                <template #clock-icon>
                    <CustomIcon
                        name="clock"
                        class="mr-1"
                        size="16"
                    />
                    Wybór godziny
                </template>
                <template #calendar-icon>
                    <CustomIcon
                        name="calendar"
                        class="mr-1"
                        size="16"
                    />
                    Wybór daty
                </template>
            </VueDatePicker>
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { displayAlert } from "@/main";

import { Capacitor } from "@capacitor/core";
import { DatePicker, DatePickerOptions } from "@capacitor-community/date-picker";

import CustomIcon from "@/components/generics/CustomIcon.vue";
import Dialog from "@/components/dialogs/Dialog.vue";

import moment from "@/helpers/moment";

export type UniversalDatePickerProps = {
    modelValue: string;
    visible: boolean;
    mode?: "datetime" | "date" | "time";
    minDate?: string;
    maxDate?: string;
    minTime?: string;
    maxTime?: string;
};
export type UniversalDatePickerOutputTime = {
    hours: number;
    minutes: number;
    seconds: number;
};

export type UniversalDatePickerMinMaxTime = {
    hours?: number | string;
    minutes?: number | string;
    seconds?: number | string;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<UniversalDatePickerProps>(), {
    visible: true,
    mode: "datetime"
});

const emit = defineEmits<{
    (e: "update:modelValue", v: string): void;
    (e: "update:visible", v: boolean): void;
}>();

/*###############
### VARIABLES ###
###############*/
const internal_date = ref(computeInitialDate(props.modelValue));

/*#############
### METHODS ###
#############*/
function openNativeDatePicker(date?: string) {
    let x = date ? moment(date) : undefined;
    if (props.mode === "time" && date) {
        x = moment(moment().format("YYYY-MM-DD") + "T" + date, "YYYY-MM-DDTHH:mm:ss", true);
    }

    if (x && !x.isValid()) {
        displayAlert.error("Niepoprawna data");
        return;
    }

    const date_picker_options: DatePickerOptions = {
        mode: props.mode === "datetime" ? "dateAndTime" : props.mode,
        doneText: "Wybierz",
        cancelText: "Anuluj",
        is24h: true,
        date: x?.toISOString(),
        ios: {
            titleFontColor: "#113a29",
            buttonFontColor: "#113a29",
            fontColor: "#112a29",
            style: "wheels"
        }
    };

    if (props.mode !== "time" && proper_min_date.value !== undefined) {
        date_picker_options.min = proper_min_date.value;
    }

    if (props.mode !== "time" && proper_max_date.value !== undefined) {
        date_picker_options.max = proper_max_date.value;
    }

    DatePicker.present(date_picker_options).then(date => {
        if (date.value === undefined) return;
        emit("update:modelValue", toCustomISOFormat(date.value));
    });

    emit("update:visible", false);
}

function handleSelect(nv: string | Date | UniversalDatePickerOutputTime) {
    if (props.mode === "time" && typeof nv === "object" && "hours" in nv) {
        const time_obj = nv as UniversalDatePickerOutputTime;
        const time_iso = formatTimeObjectToISO(time_obj);
        emit("update:modelValue", time_iso);
    } else if (nv instanceof Date) {
        emit("update:modelValue", toCustomISOFormat(nv));
    } else if (typeof nv === "string") {
        emit("update:modelValue", nv);
    } else {
        console.warn("UNKNOWN nv type @handleSelect");
    }
    closeDatePicker();
}

function computeInitialDate(
    isoStr: string | UniversalDatePickerOutputTime
): string | UniversalDatePickerOutputTime {
    if (!isoStr) {
        switch (props.mode) {
            case "datetime":
                return moment().format();
            case "date":
                return moment().format("YYYY-MM-DD");
            case "time":
                const now = moment();
                return {
                    hours: now.hours(),
                    minutes: now.minutes(),
                    seconds: now.seconds()
                };
            default:
                return moment().format();
        }
    }

    switch (props.mode) {
        case "datetime":
            return moment(isoStr).format();
        case "date":
            return moment(isoStr).format("YYYY-MM-DD");
        case "time":
            const time = moment(isoStr, "HH:mm:ss");
            return {
                hours: time.hours(),
                minutes: time.minutes(),
                seconds: time.seconds()
            };
        default:
            return moment().format();
    }
}

function formatTimeObjectToISO(timeObj: UniversalDatePickerOutputTime): string {
    return moment(timeObj).format("HH:mm:ss");
}

function toCustomISOFormat(value: Date | string): string {
    switch (props.mode) {
        case "datetime":
            return moment(value).format("YYYY-MM-DDTHH:mm:ss");
        case "date":
            return moment(value).format("YYYY-MM-DD");
        case "time":
            return moment(value).format("HH:mm:ss");
        default:
            return moment(value).format();
    }
}

function closeDatePicker() {
    emit("update:visible", false);
}

/*##############
### COMPUTED ###
##############*/
const preview_format = computed(() => {
    if (props.mode === "time") return "HH:mm";
    if (props.mode === "date") return "dd.MM.yyyy";
    return "dd.MM.yyyy HH:mm";
});

const proper_min_date = computed(() => {
    if (!props.minDate) return undefined;
    if (Capacitor.isNativePlatform()) {
        return moment(props.minDate).toISOString();
    }
    return moment(props.minDate).format("YYYY/MM/DD");
});

const proper_max_date = computed(() => {
    if (!props.maxDate) return undefined;
    if (Capacitor.isNativePlatform()) {
        return moment(props.maxDate).toISOString();
    }
    return moment(props.maxDate).format("YYYY/MM/DD");
});

const proper_min_time = computed(() => {
    if (!props.minTime) return undefined;

    if (Capacitor.isNativePlatform()) {
        return undefined;
    }

    const time = moment(props.minTime, "HH:mm:ss");
    return {
        hours: time.hours(),
        minutes: time.minutes(),
        seconds: time.seconds()
    };
});

const proper_max_time = computed(() => {
    if (!props.maxTime) return undefined;

    if (Capacitor.isNativePlatform()) {
        return undefined;
    }

    const time = moment(props.maxTime, "HH:mm:ss");
    return {
        hours: time.hours(),
        minutes: time.minutes(),
        seconds: time.seconds()
    };
});

/*##############
### WATCHERS ###
##############*/
watch(
    () => props.modelValue,
    nv => {
        internal_date.value = computeInitialDate(nv);
    }
);
watch(
    () => props.visible,
    nv => {
        if (Capacitor.isNativePlatform() && nv) {
            openNativeDatePicker(props.modelValue);
        }
    }
);
</script>
