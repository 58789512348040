<template>
    <div class="new-chat-msg-notification">
        <div class="new-chat-msg-notification__wrapper">
            <div class="new-chat-msg-notification__avatar">
                <Avatar
                    v-if="
                        chatMessage.author_type === ChatMessageAuthorType.USER &&
                        !created_by_current_user
                    "
                    size="40"
                    :image-src="chatMessage.user.profile_image?.url_xs"
                />
                <Avatar
                    v-else-if="chatMessage.author_type === ChatMessageAuthorType.SYSTEM"
                    size="40"
                    :imageSrc="RisifyLogo"
                />
            </div>
            <div
                v-if="
                    chatMessage.deleted === false &&
                    chatMessage.message_type !== ChatMessageMessageType.EVENT
                "
                class="new-chat-msg-notification__content ml-3 mb-6"
            >
                <div class="new-chat-msg-notification__name text-h7 mb-1">
                    <span class="text-weight-semibold">{{
                        chatMessage.author_type === ChatMessageAuthorType.USER
                            ? chatMessage.user.first_name + " " + chatMessage.user.last_name
                            : "Risify.pl"
                    }}</span>
                    przesłał(a) Ci wiadomość:
                </div>
                <div class="new-chat-msg-notification__message text-body-2">
                    {{ getChatMessageContentAsText(chatMessage) }}
                </div>
            </div>
            <div
                v-else-if="
                    chatMessage.deleted === false &&
                    chatMessage.message_type === ChatMessageMessageType.EVENT
                "
                class="new-chat-msg-notification__content ml-3 mb-6"
            >
                <ChatMessageAppointmentRescheduleProposalContent
                    v-if="
                        chatMessage.event.name ===
                        ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED
                    "
                    :chatMessage="chatMessage"
                    no-padding
                    hide-actions
                />
                <ChatMessageAppointmentEventContent
                    v-else
                    :chatMessage="chatMessage"
                    no-padding
                    hide-footer
                />
            </div>
        </div>
        <div class="new-chat-msg-notification__actions">
            <RisifyButton
                text
                @click="emit('clear')"
                class="mr-8"
                small
            >
                <span style="color: #d4d4d4"> Ukryj </span>
            </RisifyButton>
            <RisifyButton
                color="green"
                @click="
                    () => {
                        if ($route.name !== 'chat' || $route.params.id != chatMessage.chat) {
                            $router.push({
                                name: 'chat',
                                params: {
                                    id: chatMessage.chat
                                }
                            });
                        }
                        emitter.emit('AppointmentDetailsView::close');
                        emit('clear');
                    }
                "
                small
            >
                Przejdź do rozmowy
            </RisifyButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
    ChatMessage,
    ChatMessageAuthorType,
    ChatMessageEventName,
    ChatMessageMessageType
} from "@/types/chat-message";
import Avatar from "@/components/generics/Avatar.vue";
import ChatMessageAppointmentRescheduleProposalContent from "@/modules/chat/ChatMessageAppointmentRescheduleProposalContent.vue";
import ChatMessageAppointmentEventContent from "@/modules/chat/ChatMessageAppointmentEventContent.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import RisifyLogo from "@/assets/imgs/risify_logo_icon.svg";
import { useAuthStore } from "@/stores/auth";
import { getChatMessageContentAsText } from "@/helpers/chats";
import { emitter } from "@/plugins/eventEmitter";

const auth_store = useAuthStore();

const props = defineProps<{
    chatMessage: ChatMessage;
}>();

const emit = defineEmits(["clear"]);

const created_by_current_user = computed(() => {
    return (
        props.chatMessage.author_type === ChatMessageAuthorType.USER &&
        props.chatMessage.user._id === auth_store.user?._id
    );
});
</script>
