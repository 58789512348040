<template>
    <div
        class="avatar"
        :style="{
            width: computedSize,
            height: computedSize
        }"
    >
        <img
            class="avatar__image"
            :src="fullImagePath"
            alt="user profile image"
        />
        <div
            v-if="indicator"
            :style="{
                '--size': computedIndicatorSize,
                '--position': computedIndicatorPosition
            }"
            :class="{ 'avatar__status-indicator avatar__status-indicator--online': indicator }"
        ></div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import imagePlaceholder from "@/assets/imgs/avatar_placeholder.svg";

export type AvatarProps = {
    size: number | string;
    indicator?: boolean;
    imageSrc?: string;
};

const props = defineProps<AvatarProps>();

const computedSize = computed(() => {
    if (typeof props.size === "string") {
        return parseInt(props.size) + "px";
    }
    return props.size + "px";
});

const computedIndicatorSize = computed(() => {
    if (typeof props.size === "string") {
        return parseInt(props.size) / 5 + "px";
    }
    return props.size / 5 + "px";
});

const computedIndicatorPosition = computed(() => {
    if (typeof props.size === "string") {
        return parseInt(props.size) / 100 + "rem";
    }
    return props.size / 100 + "rem";
});

const fullImagePath = computed(() => {
    if (props.imageSrc) {
        return props.imageSrc;
    }
    return imagePlaceholder;
});
</script>
